import Footer from "../components/Footer";
import {Box, Button, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import mainImage from "../assets/CSR/CSR_mainImage.jpg"
import firstCard from "../assets/CSR/01_CSR_Munkhelyi_feltételek_biztosítás_2.jpg"
import secondCard from "../assets/CSR/02_CSR_Kis_tettek_nagy_álmok.jpg"
import thirdCard from "../assets/CSR/03_CSR_SPORT.jpg"
import fourthCard from "../assets/CSR/04_CSR_turizmus_és_fenntarthatóság.jpg"
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";


const CSR = () => {
    useScrollToTop()
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('csr.title')}`
    const history = useHistory()

    const cards = [
        {
            image: firstCard,
            title: t('csr.firstCardTitle'),
            description: t('csr.firstCardDescription')
        },
        {
            image: secondCard,
            title: t('csr.secondCardTitle'),
            description: t('csr.secondCardDescription'),
        },
        {
            image: thirdCard,
            title: t('csr.thirdCardTitle'),
            description: t('csr.thirdCardDescription'),
        },
        {
            image: fourthCard,
            title: t('csr.fourthCardTitle'),
            description: t('csr.fourthCardDescription'),
        },
    ]

    return <DrawerAppbar fade>
        <HeroImage title={t('csr.title')} description={t('csr.description')} underlineColor='#ff0000' image={mainImage}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Typography variant='h2' gutterBottom textAlign='center'>{t('csr.headline')}</Typography>
            <ImageStack images={cards}/>


        </div>
        <Footer />
    </DrawerAppbar>
}

export default CSR