

const components = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: '4px',
            }
        },
        variants: [
            {
                props: { variant: 'contained' },
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: '#7D99BA',
                    color: '#fff',
                    padding: '12px 16px',
                    gap: '10px',
                    '&:hover': {
                        background: '#97ADC8',
                    },
                    '&:active': {
                        background: '#5E738C'
                    }
                },
            }, {
                props: { variant: 'outlined' },
                style: {
                    textTransform: 'none',
                    border: '2px solid #7D99BA',
                    background: 'transparent',
                    color: '#7D99BA',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: '#EBEEF5',
                        border: '2px solid #7D99BA',
                    },
                    '&:active': {
                        background: '#E5EBF1',
                        border: '2px solid #7D99BA',
                    }
                },
            }, {
                props: { variant: 'text' },
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: 'transparent',
                    color: '#5E738C',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: '#EBEEF5',
                    },
                    '&:active': {
                        background: '#E5EBF1',
                    }
                },
            },
        ],
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: '4px',
                backgroundColor: '#F8F9FC',
            }
        }
    },
    MuiInput: {
        styleOverrides: {
            root: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
                '&:hover': {
                    content: 'none',
                }
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                border: '1px solid #F3F3F1',
                background: '#fff',
                borderRadius: '16px'
            },
            cell: {
                border: 'none'
            },
        }
    }
}

export {components}