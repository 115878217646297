import Footer from "../../components/Footer";
import {Container, Grid, Typography, Avatar, Button, Box} from "@mui/material";
import HeroImage from "../../components/HeroImage";
import useScrollToTop from "../../hooks/useScrollToTop";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {formatHuman} from "../../utils/utils";
import DrawerAppbar from "../../components/DrawerAppbar";
import ConfigContext from "../../context/ConfigContext";
import {useTranslation} from "react-i18next";

const BlogPost = () => {

    useScrollToTop()
    const {getData: fetchBlog} = useCallDataApi('blog')
    const [loading, setLoading] = useState(false)
    const [blog, setBlog] = useState({})
    const params = useParams()
    const history = useHistory()
    const {language} = useContext(ConfigContext)
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true)
        fetchBlog(`${params?.id}/`).then(b => {
            setBlog(b)
            document.title = `Jet Travel | ${b?.translations?.[language]?.title}`
        }).finally(() => setLoading(false))
    }, [params?.id])

    return <DrawerAppbar fade>
        <HeroImage title={blog?.translations?.[language]?.title} underlineColor='#ff0000' image={blog?.hero_image}/>
        <div style={{marginTop: '64px', marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container>
                <Typography variant="h2" gutterBottom>
                    {blog?.title}
                </Typography>
                <Grid container alignItems="center" style={{marginBottom: '16px'}}>
                    {/*<Avatar alt={blog?.author?.name} src={blog?.author?.profile_picture}/>*/}
                    <div style={{marginLeft: '16px'}}>
                        {/*<Typography variant="body1">*/}
                        {/*    {blog?.author?.name}*/}
                        {/*</Typography>*/}
                        <Typography variant="body2" color="textSecondary">
                            {formatHuman(new Date(blog?.timestamp))}
                        </Typography>
                    </div>
                </Grid>
                <div dangerouslySetInnerHTML={{ __html: blog?.translations?.[language]?.post }}/>
            </Container>
        </div>
        <Footer/>

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default BlogPost
