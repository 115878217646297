import Footer from "../components/Footer";
import {Box, Button, Container, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import CountUpGrid from "../components/CountUpGrid";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import mainImage from '../assets/coroporate/corporate.jpg'
import firstCard from '../assets/coroporate/tickets.jpg'
import secondCard from '../assets/coroporate/Szállásfoglalás.jpg'
import thirdCard from '../assets/coroporate/transfer.jpg'
import fourthCard from '../assets/coroporate/visa.jpg'
import fifthCard from '../assets/coroporate/saefty.jpg'
import sixthCard from '../assets/coroporate/statistics.jpg'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const Corporate = () => {
    useScrollToTop()
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('corporate.title')}`
    const history = useHistory()

    const cards = [
        {
            image: firstCard,
            title: t('corporate.firstCard'),
            description: t('corporate.firstCardDescription')
        },
        {
            image: secondCard,
            title: t('corporate.secondCard'),
            description: t('corporate.secondCardDescription')
        },
        {
            image: thirdCard,
            title: t('corporate.thirdCard'),
            description: t('corporate.thirdCardDescription')
        },
        {
            image: fourthCard,
            title: t('corporate.fourthCard'),
            description: t('corporate.fourthCardDescription')
        },
        {
            image: fifthCard,
            title: t('corporate.fifthCard'),
            description: t('corporate.fifthCardDescription')
        },
        {
            image: sixthCard,
            title: t('corporate.sixthCard'),
            description: t('corporate.sixthCardDescription')
        },
    ]
    const numbers = [
        {number: 35, unit: t('corporate.firstNumber'), description: t('corporate.firstNumberDescription')},
        {number: 350, unit: t('corporate.secondNumber'), description: t('corporate.secondNumberDescription')},
        {reversed: true, number: 5, unit: t('corporate.thirdNumber'), description: t('corporate.thirdNumberDescription')},
        {number: 3, unit: t('corporate.fourthNumber'), description: t('corporate.fourthNumberDescription')},
        {number: 34000, unit: t('home.seventhNumber'), description: t('home.seventhNumberDescription')},
    ]

    return <DrawerAppbar fade>
        <HeroImage description={t('corporate.description')} title={t('corporate.title')} underlineColor='#ff0000' image={mainImage}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 2, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('corporate.heading2')}</Typography>
                <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }} gutterBottom textAlign='center'>{t('corporate.description2')}</Typography>
                <Typography variant='h2' gutterBottom textAlign='center' marginTop={10}>{t('corporate.heading')}</Typography>
            </Container>

            <ImageStack images={cards}/>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('corporate.inNumbers')}</Typography>
                <CountUpGrid numbers={numbers} />
            </Container>

        </div>
        <Footer />
    </DrawerAppbar>
}

export default Corporate