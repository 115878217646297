import {Box, Button, Container, Stack, Typography} from "@mui/material";
import useScrollToTop from "../../hooks/useScrollToTop";
import HeroImage from "../../components/HeroImage";
import Footer from "../../components/Footer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState, useContext} from "react";
import Loading from "../../components/Loading";
import DrawerAppbar from "../../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ConfigContext from "../../context/ConfigContext";
import sport from '../../assets/Sportutak.jpeg'
import logo from '../../assets/shared/jet-sport-logo-white-01.png'
import ImageStack from "../../components/ImageStack";
import CountUpGrid from "../../components/CountUpGrid";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";


const Sports = () => {
    const {t} = useTranslation()
    const language = 'hu'
    document.title = `Jet Travel | ${t('sport.headline')}`
    useScrollToTop()
    const history = useHistory()

    const {getData: fetchSport} = useCallDataApi('sport')
    const [sports, setSports] = useState([])
    const [loading, setLoading] = useState(false)

    const numbers = [
        {number: 134, unit: t('sport.firstNumber'), description: t('sport.firstNumberDescription')},
        {number: 71, unit: t('sport.secondNumber'), description: t('sport.secondNumberDescription')},
        {number: 58, unit: t('sport.thirdNumber'), description: t('sport.thirdNumberDescription')},
        {number: 88430, unit: t('sport.fourthNumber'), description: t('sport.fourthNumberDescription')},
    ]

    useEffect(() => {
        setLoading(true)
        fetchSport().then(r => setSports(r)).finally(() => setLoading(false))
    }, [])


    return <DrawerAppbar fade>
        <HeroImage logo={logo} description={t('sport.description')} title={t('sport.headline')} underlineColor='#ff0000' image={sport}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('sport.headline2')}</Typography>
                <Typography variant='body1' gutterBottom textAlign='center'>{t('sport.description2')}</Typography>
            </Container>
            <Typography variant='h2' gutterBottom textAlign='center'>{t('sport.actualSport')}</Typography>
            <ImageStack images={sports?.filter(s => s?.actual)?.sort((a, b) => a?.order - b?.order)?.map(sport => ({image: sport?.image, title: sport?.translations[language]?.name, description: sport?.translations[language]?.summary, link: `/sport/${sport?.slug}`}))}/>

            <Container sx={{marginBottom: 10, marginTop: 10}}>
                <Typography variant="h2" gutterBottom textAlign="center">
                    {t('sport.inNumbers')}
                </Typography>
                <CountUpGrid numbers={numbers} />
            </Container>

            <Typography marginTop={3} variant='h2' gutterBottom textAlign='center'>{t('sport.previousSport')}</Typography>
            <ImageStack images={sports?.filter(s => !s?.actual)?.sort((a, b) => a?.order - b?.order)?.map(sport => ({image: sport?.image, title: sport?.translations[language]?.name, description: sport?.translations[language]?.summary, link: `/sport/${sport?.slug}`}))}/>
        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Sports