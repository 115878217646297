import HeroImage from "../components/HeroImage";
import mainImage from "../assets/sustainalbility/Fenntarthatóság.jpeg";
import {Box, Button, Grid, Typography} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ImageStack from "../components/ImageStack";
import Footer from "../components/Footer";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import useScrollToTop from "../hooks/useScrollToTop";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import Loading from "../components/Loading";
import useCallDataApi from "../hooks/data";
import EmployeeAvatar from "../components/EmployeeAvatar";
import ConfigContext from "../context/ConfigContext";
import {repairImageUrl} from "../utils/utils";

const Branch = () => {
    useScrollToTop()
    const {t} = useTranslation()
    const history = useHistory()
    const {getData: fetchEmployees} = useCallDataApi('colleague');
    const {getData: fetchBranches} = useCallDataApi('branch');
    const [employees, setEmployees] = useState([]);
    const [branch, setBranch] = useState({});
    const [loading, setLoading] = useState(false);
    const {language, baseUrl} = useContext(ConfigContext)
    const params = useParams()

    useEffect(() => {
        setLoading(true);

        Promise.all([fetchEmployees(`${params?.id}/for_branch`), fetchBranches(params?.id)])
            .then(([employees, branches]) => {
                setEmployees(employees);
                setBranch(branches);
            })
            .finally(() => setLoading(false));
    }, []);

    document.title = `Jet Travel | ${branch?.translations?.[language]?.name}`

    return <DrawerAppbar fade>
        <HeroImage title={branch?.translations?.[language]?.name} underlineColor='#ff0000' image={branch?.image}/>
        <div style={{ marginBottom: '64px'}}>
            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>
            <Box mb={4}>
                {/*<Typography variant="h2" gutterBottom textAlign="center">*/}
                {/*    {branch?.translations?.[language]?.name}*/}
                {/*</Typography>*/}
                <Grid container spacing={2} justifyContent="center">
                    {employees.map(employee => (
                        <Grid item key={employee.id} xs={12} sm={6} md={4} lg={3}>
                            <EmployeeAvatar
                                firstName={employee?.first_name}
                                lastName={employee?.last_name}
                                imageUrl={repairImageUrl(employee?.profile_picture, baseUrl)}
                                gifUrl={repairImageUrl(employee?.gif, baseUrl)}
                                title={employee?.translations?.[language]?.title}
                                phone={employee?.translations?.[language]?.phone}
                                email={employee?.translations?.[language]?.email}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

        </div>
        <Footer />
        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Branch