const mandatory = [
    {
        url: '/impressum',
        name: 'footer.impressum'
    },
    {
        url: '/privacy-policy',
        name: 'footer.privacy'
    },
    {
        url: '/complaints',
        name: 'footer.comaplaints'
    },
    {
        url: '/aszf',
        name: 'footer.aszf'
    },
    {
        url: '/travel-policy',
        name: 'footer.travelPolicy'
    },
    {
        url: '/gdpr',
        name: 'footer.gdpr'
    },
]

export default mandatory