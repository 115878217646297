import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
    const text = 'A jelen adatvédelmi tájékoztató (a továbbiakban: tájékoztató) tartalmazza a Jet Travel Kft. (a továbbiakban: Adatkezelő) által végzett utazásszervezési és közvetítési tevékenységekkel kapcsolatos, a személyes adatok kezelésére vonatkozó szabályokat, valamint az adatkezelésre vonatkozó tájékoztatást. A tájékoztató kiterjed az Adatkezelő honlapjával (https://www.jettravel.hu) összefüggő működésre is (így különösen a kapcsolatfelvételre).\n' +
        '\n' +
        'Kérjük, hogy az adatkezelési nyilatkozatok megtétele előtt figyelmesen olvassa el a jelen adatvédelmi tájékoztatót! Amennyiben az abban foglaltakkal kapcsolatosan kérdése merülne fel, a Jet Travel Kft. munkatársa készséggel áll rendelkezésre az alábbi központi elérhetőségen:\n' +
        '\n' +
        'adatkezeles@jettravel.hu\n' +
        '\n' +
        'A honlap kapcsolati funkcióinak használatával Ön beleegyezését adja a személyes adatok kezeléséhez kapcsolattartási célra. Amennyiben az Adatkezelő által nyújtott szolgáltatásokkal összefüggésben érdeklődik, illetve igényli az Adatkezelő szolgáltatásait, úgy tájékoztatjuk, hogy adatait az általános adatvédelmi rendelet [1] (a továbbiakban: GDPR) 6 cikk (1) bekezdés b) pontja alapján kezeljük.\n' +
        '\n' +
        'Az Adatkezelő fenntartja a jogot továbbá a jelen tájékoztató részbeni vagy teljes módosítására az adatkezelésre vonatkozó biztonság és jogszerűség mindenkori sérelme nélkül, ezért kérjük rendszeresen kövesse figyelemmel annak tartalmát! A módosítások rövid összefoglalását a hatálybalépés előtt legalább 30 napos időszakra [módosítások aloldalon] elhelyezzük, de a módosítással kapcsolatos információk önmagukban történő áttekintése a teljes, a módosításokkal egységes szerkezetbe foglalt adatvédelmi tájékoztató megismerését nem teszi nélkülözhetővé. A mindenkor hatályos, egységes szerkezetbe foglalt adatvédelmi tájékoztató elérhető: LINK.\n' +
        '\n' +
        '1. Az adatkezelő\n' +
        '\n' +
        'Adatkezelő neve : "Jet Travel" Idegenforgalmi Szolgáltató és Kereskedelmi Korlátolt Felelősségű Társaság\n' +
        '\n' +
        'Székhelye : 1113 Budapest, Bocskai út 77-79/B 3. em.\n' +
        '\n' +
        'Adatkezelés NAIH azonosítója :\n' +
        '\n' +
        'Elérhetőségek:\n' +
        '\n' +
        'telefon: +36 1 209 2110\n' +
        '\n' +
        'e-mail: adatkezeles@jettravel.hu\n' +
        '\n' +
        '\n' +
        '\n' +
        '2. Általános keretek\n' +
        '\n' +
        '2.1 A jelen tájékoztató összefoglalja azon magas szintű adatvédelmi illetve adatkezelési elveket, szabályokat, amelyeket az Adatkezelő szolgáltatásainak nyújtásával, valamint saját működtetésével összefüggésben önmagára nézve kötelezőnek ismer el a mindenkor hatályos jogszabályi előírások maradéktalan megtartása mellett.\n' +
        '\n' +
        'Utóbbiak a jelen szabályzatban való kiemelés híján is részét képezik az Adatkezelő gyakorlatának.\n' +
        '\n' +
        '2.2 Az adatkezelési tájékoztató kialakítása során az Adatkezelő figyelembe vette a GDPR és az Infotv. [2] rendelkezéseit, a Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlásait, a vonatkozó joggyakorlatot, hatósági, bírósági és Alkotmánybírósági döntéseket, valamint az adatvédelemmel összefüggésben kialakuló új európai trendeket.\n' +
        '\n' +
        '2.3 A jelen szabályzatban alkalmazott fogalmak minden esetben megegyeznek a GDPR 4. cikke szerinti értelmezésekkel. Érintettnek az Adatkezelő által végzett szolgáltatásokat közvetlenül igénybe vevő természetes személy (így tehát utazáson részt vevő, repülőjeggyel utazó stb.) minősül.\n' +
        '\n' +
        '2.4 Az Adatkezelő szolgáltatásaival összefüggésben személyes adatok megadására három módon kerülhet sor:\n' +
        '\n' +
        'a) a személyes adatokat az érintett maga adja meg (pl. saját utazásának az Adatkezelő általi megszervezésével összefüggésben);\n' +
        '\n' +
        'b) valamely, az Adatkezelő szerződéses partnere adja meg azokat, amely esetben a vonatkozó szerződés alapján az adatkezelés jogszerűségét az Adatkezelő valamint a partner közötti szerződés alapján az Adatkezelő vélelmezi, ahogyan az érintettnek a szerződéses partner felé, valamint a személyes adatoknak az Adatkezelő részére történő továbbítás vonatkozásában a GDPR 6. cikk (1) bekezdés a) pontja szerinti hozzájárulását is az adatkezelésre, az Adatkezelő szolgáltatásaival összefüggő célra;\n' +
        '\n' +
        'c) az Adatkezelő szolgáltatásait közvetítő külső szolgáltató által kerül megküldésre, amelyre az érintett a külső szolgáltatót felhatalmazta.\n' +
        '\n' +
        'Az adatszolgáltatás az Adatkezelő irányában minden esetben önkéntesen, a b) pont esetében az Adatkezelő valamint a szerződéses partnere közötti megállapodáson alapuló módon történik.\n' +
        '\n' +
        '2.5 Az adatkezelés célja az Adatkezelő által végzett utazásszervezési szolgáltatások nyújtása – azaz az utazások megszervezése, előkészítése, lebonyolítása, valamint egyes utak esetében az azzal kapcsolatos elégedettség felmérése (Hajós-és egyéb csoportos utak lezárását követően). Az adatokat az Adatkezelő az utazással kapcsolatosan általa kiállított számlák megalapozottságának igazolása, valamint a szerződéses partnerével (2.4-es pont esetében), illetve a külső szolgáltatóval (2.5-ös pont esetében) kötött szerződés teljesítésének céljára szintén kezeli.\n' +
        '\n' +
        '2.6 Az Adatkezelő hírlevél szolgáltatást kizárólag az arra feliratkozott (az elégedettség mérési kérdőívek kitöltése során, valamint egyes rendezvényeken a vonatkozó beleegyező formanyomtatvány kitöltésével). A hírlevélre feliratkozottak személyes adatait az Adatkezelő harmadik személynek nem továbbítja.\n' +
        '\n' +
        '3. A https://www.jettravel.hu böngészésével összefüggésben használt adatok\n' +
        '\n' +
        '3.1 A honlap az alábbiakban részletezett fajtájú sütiket használ. A süti egy kis szöveges fájl, amelyet a webhely helyez el a látogatók eszközén. Az eszköz a sütiket minden további látogatáskor visszaküldi a weboldalra vagy egy másik olyan weboldalra, amely felismeri az adott sütit. A sütiket a weboldal működéséhez, illetve hatékonyabb működéséhez használják, továbbá azok információt nyújtanak a weboldal tulajdonosai számára, melyeket felhasználva megfelelőbb és egyénre szabottabb szolgáltatásokat tudnak nyújtani a felhasználók részére. Amikor a felhasználók meglátogatják a honlapot, az oldal információt gyűjthet sütik és más hasonló technológiák használatával.\n' +
        '\n' +
        '3.2 Ideiglenes sütik\n' +
        '\n' +
        'Ezek a sütik szükségesek a navigációhoz a weboldalunkon. Ilyenek például azok a sütik, amelyek lehetővé teszik az Ön számára a bejelentkezést az ügyféloldalra, vagy, hogy termékeket helyezzen a vásárlókosarába. Ezek a sütik 30 perc elteltével újra elhelyezésre kerülnek, vagy az oldal elhagyása után törlődnek. A bejelentkezéshez vagy vásárláshoz szükséges sütik 30 perc elteltével törlődnek.\n' +
        '\n' +
        '3.3 Állandó sütik\n' +
        '\n' +
        'Ezek a sütik arra szolgálnak, hogy a böngészője újra felismerésre kerüljön, amikor a következő alkalommal látogatást tesz a weboldalunkon. Ezek a sütik addig tárolódnak az Ön számítógépén, ameddig manuálisan nem törli azokat.\n' +
        '\n' +
        '3.4 Teljesítményt segítő sütik\n' +
        '\n' +
        'Ezek a sütik azt a célt szolgálják, hogy anonim adatokat gyűjtsünk az Ön felhasználói szokásairól a weboldalunkra vonatkozóan. Ezek a sütik regisztrálják a meglátogatott aloldalakat és linkeket. Ez teszi lehetővé számunkra, hogy megfelelőbben tudjuk kezelni az Ön preferenciáit, amikor legközelebb ellátogat a weboldalunkra. Ezáltal érdekes információkat és ajánlatokat tudunk adni az Ön részére. Ezeket az adatokat 30 napig tároljuk.\n' +
        '\n' +
        '3.5 Működést segítő sütik\n' +
        '\n' +
        'Ezek a sütik szolgálnak a beállítások tárolására, amelyeket Ön a weboldalunkon beállított (mint például a nyelvi beállítások, vagy a betűméret beállítása). Ezáltal az Ön kényelme érdekében javul a weboldalunk funkcionalitása. Ezek a sütik 30 perc elteltével törlődnek.\n' +
        '\n' +
        '3.6 Harmadik személyektől származó sütik\n' +
        '\n' +
        'Ezek a sütik arra szolgálnak, hogy anonim adatokat gyűjtsenek az Ön felhasználói szokásairól a weboldalunkon és más weboldalakon. Ez teszi lehetővé számunkra, hogy megfelelőbben tudjuk kezelni az Ön preferenciáit, amikor legközelebb ellátogat a weboldalunkra. Ezáltal érdekes információkat és ajánlatokat tudunk adni az Ön részére.\n' +
        '\n' +
        'A Google Analytics, Pingdom, Hotjar, Polyfill, BingAds és GoogleAdwords esetében is harmadik személyektől származó sütik kerülnek alkalmazásra, amelyekkel kapcsolatban a következőkben talál részletesebb információt:\n' +
        '\n' +
        '3.7 Google Analytics\n' +
        '\n' +
        'Ez a weboldal a Google (Universal) Analytics-ot használja, amely a Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) web-elemző szolgáltatása. A Google Analytics sütiket használ, amelyek a weboldalunk használatát elemzik.\n' +
        '\n' +
        'A sütik által nyert információk a weboldal használatáról (beleértve az Ön IP-címét és a meglátogatott weboldalak URL-címeit) általában az USA székhelyű Google egyik szerverére kerülnek továbbításra és tárolásra. Az IP-anonimizálás aktiválásával ezen a weboldalon az IP-címek az Európai Unió tagországain belüli vagy az Európai Gazdasági Közösség szerződéses államaiba történő továbbítás előtt lerövidítésre kerülnek. Csak kivételes esetben kerül a teljes IP-cím továbbításra az USA székhelyű Google valamelyik szerverére, ahol lerövidítésre kerül. A Google ezeket az információkat a mi érdekünkben használja fel, hogy kiértékelje az Ön felhasználási szokásait a weboldalunkon, hogy riportokat állítson össze a weboldal aktivitásairól és további, a weboldallal és az internethasználattal kapcsolatos szolgáltatásokat végezzen a részünkre. A Google Analytics által az Ön böngészőjéről továbbított anonim IP-cím nem kerül összevonásra a Google többi adatával. Ezek az adatok 6 hónapig kerülnek tárolásra.\n' +
        '\n' +
        'Nem tároljuk azokat az adatokat, amelyeket a Google Analytics gyűjtött.\n' +
        '\n' +
        'Ön megakadályozhatja a sütik által generált, a weboldal használatára vonatkozó adatok összegyűjtését (beleértve az Ön IP-címét) a Google részére, valamint azok Google általi feldolgozását, ha letölti és installálja a következő linken elérhető Browser Plugin-t: http://tools.google.com/dlpage/gaoptout?hl=de\n' +
        '\n' +
        'A Google felhasználási feltételeire és adatvédelmi nyilatkozatára vonatkozó részletes információk a következő elérhetőségeken találhatóak: https://marketingplatform.google.com/about/analytics/terms/hu/, illetve https://www.google.at/intl/at/policies/.\n' +
        '\n' +
        'A sütik általunk történő alkalmazásának jogalapja az Ön hozzájárulása, valamint a jogos érdekünk a sütik egyes típusainál megnevezett célok elérésre vonatkozóan.\n' +
        '\n' +
        '3.8 HotJar\n' +
        '\n' +
        'Ez a weboldal a Hotjar Ltd. (http://www.hotjar.com, Level 2, St Julian\'s Business Centre, 3, Elia Zammit Street, St Julian\'s STJ 1000, Málta, Európa) („Hotjar“) elemző szoftverét használja. A Hotjar segítségével lehetséges a weboldalunkon történő felhasználói viselkedés (kattintások, egérműveletek, görgetések, stb.) mérése és kiértékelése. Az információk, amelyek ennek során a „Tracking Code” és a sütik által a weboldalunkon generálódnak az Ön látogatásáról, az írországi Hotjar szerverre kerülnek továbbításra, ahol tárolásra kerülnek. A Tracking Code által a következő (anonim) információk kerülnek összegyűjtésre: az Ön készülékének képernyőmérete, a készülék típusa és a böngésző-információk, a földrajzi helyzet (csak az ország), a preferált nyelv a weboldalunk megjelenítéséhez, a felhasználói interakciók, az egérműveletek (mozgatás, pozíció és kattintások), a billentyűzet aktivitásai és a Log adatok.\n' +
        '\n' +
        'Ezek a sütik 365 nap elteltével törlésre kerülnek.\n' +
        '\n' +
        'Nem tároljuk a HotJar-információkat.\n' +
        '\n' +
        'Ön a HotJar sütik tárolását csak a böngészője megfelelő beállításával akadályozhatja meg, ehhez a böngészőt privát/inkognitó ablakban kell futtatnia. A Hotjar felhasználási feltételeire és adatvédelmi nyilatkozatára vonatkozó részletesebb információk a következő elérhetőségen találhatóak: https://www.hotjar.com/legal/policies/privacy.\n' +
        '\n' +
        'A sütik általunk történő alkalmazásának jogalapja az Ön hozzájárulása, valamint a jogos érdekünk a sütik egyes típusainál megnevezett célok elérésre vonatkozóan.\n' +
        '\n' +
        '4. Kezelt adatok köre\n' +
        '\n' +
        '4.1 Az Adatkezelő a szolgáltatásainak nyújtásával összefüggésben az érintetteknek kizárólag a jelen tájékoztatóban foglalt személyes adatait kezeli, amelyek az alábbiakra terjednek ki:\n' +
        '\n' +
        'a) corporate utak szervezése során:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- mobiltelefon szám\n' +
        '\n' +
        '- nem\n' +
        '\n' +
        '- születési dátum\n' +
        '\n' +
        '- útlevél szám (kiállítási dátum, lejárati dátummal)\n' +
        '\n' +
        '- különleges étkezésre vonatkozó igények\n' +
        '\n' +
        'b) incentive utak szervezése során:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- mobiltelefonszám\n' +
        '\n' +
        '- nem\n' +
        '\n' +
        '- születési dátum\n' +
        '\n' +
        '- útlevél szám (kiállítási dátum, lejárati dátummal)\n' +
        '\n' +
        '- személyi igazolványszám\n' +
        '\n' +
        '- különleges étkezésre vonatkozó igények\n' +
        '\n' +
        'c) eventek szervezése során:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- mobiltelefonszám\n' +
        '\n' +
        '- különleges étkezésre vonatkozó igények\n' +
        '\n' +
        'd) cruise-, adventure-, sport utak szervezése során:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- mobiltelefonszám\n' +
        '\n' +
        '- születési dátum\n' +
        '\n' +
        '- útlevél szám (kiállítási dátum, lejárati dátummal)\n' +
        '\n' +
        '- személyi igazolványszám\n' +
        '\n' +
        '- különleges étkezésre vonatkozó igények\n' +
        '\n' +
        'e) repülőjegy vásárlása során:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- mobiltelefonszám\n' +
        '\n' +
        '- nem\n' +
        '\n' +
        '- születési dátum\n' +
        '\n' +
        '- útlevél szám (kiállítási dátum, lejárati dátummal)\n' +
        '\n' +
        '- különleges étkezésre vonatkozó igények\n' +
        '\n' +
        'f) kapcsolatfelvétel esetén:\n' +
        '\n' +
        '- név\n' +
        '\n' +
        '- e-mail cím\n' +
        '\n' +
        '- telefonszám\n' +
        '\n' +
        'Az Adatkezelő a szolgáltatások nyújtásával összefüggésben kezel továbbá a számlázáshoz szükséges adatokat is – így az utazó nevét, az utazás célpontját, idejét, az utazás díját és felszámított költségeket, valamint a számlázási címet (amely akkor minősül személyes adatnak amennyiben a számlát magánszemély fizeti). Ezen számlázási adatokat az Adatkezelő a számla kiállításának évét követő 8 évig tárolja.\n' +
        '\n' +
        '4.2 Az Adatkezelő az érintettek, valamint a szerződéses és külső partnerek által szolgáltatott megfelelőségét és helytállóságát nem ellenőrzi. A 2.4. b) és c) pont szerinti kitételt a szerződéses vagy külső partner által szolgáltatott személyes adatok jogszerű kezelésére vonatkozóan, valamint a jelen adatkezelési tájékoztató elérhetőségét az Adatkezelő valamint a szerződéses partner közötti szerződés tartalmazza, az alapján szolgáltatott adatok esetében az adatkezelés jogszerűségét, az arra adott érintetti felhatalmazást az Adatkezelő egyedileg szintén nem ellenőrzi, és igazoltnak tekinti.\n' +
        '\n' +
        '4.3 A kezelt adatokat az Adatkezelő az utazás befejezésének évét követően az utazással kapcsolatos szerződés teljesítése, valamint a számlák hitelességének alátámasztása érdekében további 5 évig kezeli. Az ezen időszak alatt kezdeményezett új szolgáltatás igénybevételével összefüggésben Adatkezelő vélelmezi, hogy a már meglévő adatok vonatkozásában az érintett az új szolgáltatás megrendelésével felhatalmazást ad azok kezelésére az utazás megszervezése, előkészítése, illetve lebonyolítása – illetve a kapcsolódó állami adatszolgáltatási kötelezettségek teljesítésére az újbóli adatszolgáltatás elkerülése érdekében. Az időközben megváltozott adatokról való tájékoztatás az érintett feladata.\n' +
        '\n' +
        'Amennyiben az érintett az Adatkezelő szolgáltatását biztosan nem veszi igénybe, úgy a személyes adatok megszerzését követő 1 hónapon adatai törlésre kerülnek.\n' +
        '\n' +
        '4.4 Az egyes személyes adatokat az Adatkezelő a szolgáltatások nyújthatósága, az egyes utazások megszervezése, lebonyolítása érdekében továbbítja harmadik személyekhez – így különösen a szolgáltatás jellegének megfelelően szállodáknak, repülőjegy foglaló rendszerek üzemeltetőinek, repülőjegy értékesítő vállalkozásoknak, autókölcsönzőknek, az utazást biztosító hajótársaságoknak, utazási biztosítóknak, stb. Az érintett adatai kizárólag olyan személyhez kerülhetnek továbbításra, amely az érintett számára közvetlen, az utazással összefüggő szolgáltatást biztosít.\n' +
        '\n' +
        '4.5 A 4.1 a) és e), valamint esetenként (amennyiben az utazás repülőjegy vásárlását igényli) a 4.1. b), c), d) pontok vonatkozásában az érintettek hozzájárulnak, hogy a személyes adataikat repülőjegy helyfoglaló rendszerbe az Adatkezelő feltöltse, mint a továbbiakban utazáson potenciálisan részt vevő személyek a 2.4. b) szerinti szerződések teljesítése során. A feltöltéssel elkerülhető a személyes adatok ismételt megadása a szerződéses partnereken keresztül. Az adatok a 4.3-as pont szerint kerülnek törlésre ezen rendszerből is.\n' +
        '\n' +
        '4.6 Egyes szolgáltatások nyújtása szükségessé teszi az adatoknak harmadik országokba történő továbbítását, amelyet az Adatkezelő kizárólag a GDPR 45, 46. valamint 49. cikke szerint végez. Amennyiben az adattovábbításhoz a GDPR 49. cikke szerinti hozzájárulás szükséges, úgy azt megelőzően arról az Adatkezelő az érintettet egyedileg tájékoztatja elektronikus levél útján.\n' +
        '\n' +
        '4.7 Ajánlatkérés esetén az Adatkezelő kezeli a megkereső fél adatait (így a megkereső nevét, e-mail címét, telefonszámát, valamint a kapcsolattartó nevét, e-mail címét, telefonszámát), amelyet az ajánlat elfogadására nyitva álló határidő leteltétől számított a 30 napon belül töröl.\n' +
        '\n' +
        '4.8 Az Adatkezelő kezeli továbbá a hozzá álláshirdetésekkel összefüggésben jelentkezéseket benyújtók személyes adatait (így különösen az önéletrajzban, valamint a jelentkezésben szereplőket). Amennyiben azt a jelentkező ellenkezőleg nem kéri, az álláshirdetéssel kapcsolatos adatokat a pozíció betöltését követő próbaidő eredményes letöltését követő 7 napon belül az Adatkezelő törli.\n' +
        '\n' +
        '4.9 Szintén kezeli az Adatkezelő az Edutus képzéssel kapcsolatosan hozzá jelentkezettek személyes adatait, amelyet az önéletrajzában az érintett megad – ezeket a képzés befejezését követő 30 napon belül az Adatkezelő törli.\n' +
        '\n' +
        '4.10 Az adatkezelések hiányában az Adatkezelő az utazásszervezési tevékenységével kapcsolatos működését nem lenne képes ellátni, a képzési és munkaerőfelvételi folyamatait végrehajtani, így azok az utazási-, képzési-, és munkaszerződésekhez elengedhetetlenül szükségesek, egyben az ahhoz szükséges minimális adatkörre is korlátozódnak. Az adatok megadása az érintettek részéről minden esetben önkéntes.\n' +
        '\n' +
        '4.11 A tárolt adatokhoz a 2.5. és 2.6-ps pontokban jelzett céllal kizárólag az Adatkezelőnek az erre felhatalmazott munkatársai férhetnek hozzá. Az adatok kezelését belső adatvédelmi előírások rendezik, az adatkezelés azok maradéktalan megtartásával történik. Az Adatkezelő külső adatfeldolgozót, valamint további adatkezelőt nem vesz igénybe.\n' +
        '\n' +
        '\n' +
        'Tájékoztatási, valamint korrekciós lehetőségek\n' +
        '\n' +
        '4.12 Az Adatkezelő irányába teendő kérések, nyilatkozatok megtételére kizárólag írásban (papír alapon ajánlott, vagy tértivevényes levélben, illetve e-mail-ben) nyílik lehetőség, amelyek postai úton való feladás esetében a címzett általi igazolt átvétel napján, e-mailben való elküldés esetén pedig – a kézbesítés sikertelenségéről tájékoztató válaszüzenet hiányában – a következő munkanapon tekinthetők kézbesítettnek.\n' +
        '\n' +
        '4.13 Az érintett a GDPR III. fejezete szerinti kéréseit [így különösen az adatokhoz való hozzáférésre (15. cikk), az adatok helyesbítésére (16. cikk), törlésére (17. cikk) valamint korlátozására (18. cikk) vonatkozóan) az Adatkezelő haladéktalanul, de legfeljebb 3 munkanapon belül teljesíti vagy arra válaszol. Az érintett kérheti továbbá a GDPR 20. cikke szerinti adathordozási jogához kapcsolódóan adatainak .doc formátumban való biztosítását is.\n' +
        '\n' +
        '4.14 Az Adatkezelő tevékenysége, adatkezelése, és továbbítása során semmilyen formában nem változtatja meg az adatokat – ide nem értve az érintett kérésére történő korrekciók esetét.\n' +
        '\n' +
        '4.15 Bármelyik érintett kezdeményezheti az Adatkezelő elérhetőségein egyes személyes adatainak törlését (adatkezeléshez történő jóváhagyás visszavonása). Ilyen irányú kérdéseknek az Adatkezelő haladéktalanul, de legkésőbb 3 munkanapon belül eleget tesz.\n' +
        '\n' +
        'Az adatok törlésével az Adatkezelő rendszerében, valamint a 4.5 pontban megjelölt rendszerben tárolt, a felhasználó beazonosítására szolgáló adatok törlésre kerülnek, ugyanakkor az utazások szervezése és lebonyolítása érdekében szükséges adatokat a szolgáltatás ismételt igénybevétele során az érintettnek újra meg kell adnia. A jelen pont a jogszabályi, valamint a szerződéses partnerekkel (2.4. b) pont) valamint külső szolgáltatókkal (2.4. c) pont) szemben fennálló kötelezettségek igazolása érdekében szükséges adatkezelések törlését nem érinti – ezen adatok kizárólag a jogszabályi előírások által lehetővé tett határidő elteltét követően törölhetőek, így ezen adatok tekintetében az adtakezelés visszavonására irányuló jog korlátozottan gyakorolható.\n' +
        '\n' +
        '4.16 Jogellenes, megtévesztő adat használata, illetve a személyes, vagy különleges adatokkal való visszaélés bűncselekményt, illetőleg polgári jogi igényt alapozhat meg, amelynek gyanúja esetén az Adatkezelő az adatokat a lefolytatandó eljárás időtartamára is jogosult megőrizni.\n' +
        '\n' +
        '4.17 Adatkezelő jogosult és köteles minden olyan rendelkezésére álló és általa szabályszerűen tárolt adatot az illetékes hatóságoknak továbbítani, amelyre őt jogszabály vagy jogerős hatósági kötelezés kötelezi. Ilyen adat továbbítása, valamint az ebből származó következmények miatt az Adatkezelő nem tehető felelőssé.\n' +
        '\n' +
        '4.18 A Felhasználó jogérvényesítési lehetőségeit az információs önrendelkezési jogról és az információszabadságról 2011. évi CXII. törvény, valamint a Polgári Törvénykönyvről szóló 2013. évi V. törvény alapján a hatáskörrel és illetékességgel rendelkező bíróság előtt gyakorolhatja, továbbá bármilyen személyes adattal kapcsolatos kérdésben kérheti a Nemzeti Adatvédelmi és Információszabadság Hatóság eljárását is (1125 Budapest Szilágyi Erzsébet fasor 22/C; postacím: 1530 Budapest, Pf. 5., ugyfelszolgalat@naih.hu, +36 (1) 391-1400).\n' +
        '\n' +
        '2024. március 7.\n' +
        '\n' +
        '[1] az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (továbbiakban: GDPR)\n' +
        '\n' +
        '[2] az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (a továbbiakban: Infotv.)\n' +
        '\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>Adatkezelési szabályzat</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
                <Typography
                    component="span"
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    onClick={() => window.print()}
                >
                    Az adatvédelmi tájékoztatónkat ide kattintva töltheti le PDF formátumban
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default PrivacyPolicy