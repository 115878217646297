import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

// Dynamic scrolling animation based on the number of logos
const slide = (duration) => keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;


const Logos = styled(Box)({
    overflow: "hidden",
    padding: "60px 0",
    background: "white",
    whiteSpace: "nowrap",
    position: "relative",
    '&:hover': {
        '& .scrolling-logos': {
            animationPlayState: 'paused',
        },
    },
})

const LeftFade = styled(Box)({
    position: "absolute",
    top: 0,
    width: "20%",
    height: "100%",
    zIndex: "2",
    left: "0",
    background: "linear-gradient(to left, rgba(255, 255, 255, 0), white)",
})

const RightFade = styled(Box)({
    position: "absolute",
    top: 0,
    width: "20%",
    height: "100%",
    zIndex: "2",
    right: "0",
    background: "linear-gradient(to right, rgba(255, 255, 255, 0), white)",
})

const ScrollingLogos = styled(Box)(({ duration, theme }) => ({
    display: "inline-block",
    animation: `${slide(duration)} ${duration}s linear infinite`,
}));

const LogoScroller = ({ logos, speed = 2 }) => {
    const logoCount = logos.length;

    const secs = Math.round((4/speed) * 10) / 10;

    const duration = logoCount * secs;

    return (
        <Logos>
            <LeftFade/>
            <ScrollingLogos className="scrolling-logos" duration={duration}>
                {logos.map((logo, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={logo}
                        alt={`logo-${index}`}
                        sx={{ height: 100, marginRight: 2 }}
                    />
                ))}
            </ScrollingLogos>
            <ScrollingLogos className="scrolling-logos" duration={duration}>
                {logos.map((logo, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={logo}
                        alt={`logo-${index}`}
                        sx={{ height: 100, marginRight: 2 }}
                    />
                ))}
            </ScrollingLogos>
            <RightFade/>
        </Logos>
    );
};

export default LogoScroller;
