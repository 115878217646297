import {Box, Button, Container, Grid, MenuItem, Stack, Typography} from "@mui/material";
import useScrollToTop from "../../hooks/useScrollToTop";
import HeroImage from "../../components/HeroImage";
import Footer from "../../components/Footer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState, useContext} from "react";
import Loading from "../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomSelect from "../../components/formValidation/CustomSelect";
import {
    validateEmail,
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../components/formValidation/validators";
import {useSnackbar} from "notistack";
import {thousandSeparator} from "../../utils/utils";
import DrawerAppbar from "../../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ConfigContext from "../../context/ConfigContext";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import logo from '../../assets/shared/jet-sport-logo-white-01.png'


const SportCard = () => {

    useScrollToTop()
    const {t} = useTranslation()
    const language = 'hu'
    const history = useHistory()
    const {getData: fetchCards} = useCallDataApi('sport-cards')
    const [sportCard, setSportCard] = useState({})
    const [loading, setLoading] = useState(false)

    const params = useParams()

    useEffect(() => {
        setLoading(true)
        fetchCards(`${params?.id}/`).then(r => {
            setSportCard(r)
        }).finally(() => setLoading(false))
    }, [])

    document.title = `Jet Travel | ${sportCard?.translations?.[language]?.name}`



    return <DrawerAppbar fade>
        <HeroImage logo={logo} title={sportCard?.translations?.[language]?.name} underlineColor='#ff0000' image={sportCard?.image}/>
        <div style={{marginBottom: '64px'}}>

            <Box sx={{margin: 3, display: 'flex', gap: 2}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>
            <Container>
                <div dangerouslySetInnerHTML={{ __html: sportCard?.translations?.[language]?.description }}/>
            </Container>
        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default SportCard