import {format} from "date-fns";

const formatHuman = (date) => {
    try {
        return format(date, 'yyyy-MM-dd')
    } catch (e) {
        return null
    }
}

// const repairImageUrl = (fileUrl, baseUrl) => {
//     console.log('fileUrl: ' + fileUrl)
//     if (fileUrl?.startsWith('http')) return fileUrl
//     let fullUrl = baseUrl + '/' + fileUrl;
//     console.log('fullUrl: ' + fullUrl)
//     return fullUrl.replace(/(?<!https?:)\/\//g, '/')
// }

const repairImageUrl = (fileUrl, baseUrl) => {
    console.log(fileUrl)
    // If fileUrl starts with https, return it as is
    if (fileUrl?.startsWith("https://") || fileUrl?.startsWith("http://")) {
        return fileUrl
    }

    // // If fileUrl starts with http, convert it to https
    // if (fileUrl.startsWith("http://")) {
    //     return fileUrl.replace("http://", "https://")
    // }

    // Remove any trailing slash from baseUrl and leading slash from fileUrl to avoid double slashes
    const cleanedBaseUrl = baseUrl?.endsWith('/') ? baseUrl?.slice(0, -1) : baseUrl;
    const cleanedFileUrl = fileUrl?.startsWith('/') ? fileUrl?.slice(1) : fileUrl;

    // Return the concatenated URL
    return `${cleanedBaseUrl}/${cleanedFileUrl}`;
}

const createImageUrl = (image) => {
    if (image) return URL.createObjectURL(image)
    return image
}

const thousandSeparator = (num) => {
    if (num === undefined) return num
    if (num === null) return num
    if (num === '') return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export {formatHuman, repairImageUrl, thousandSeparator, createImageUrl}