import i18n from "../../i18n";


const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)
    return {
        valid: res,
        error: i18n.t('errors.charError')
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    return {
        valid: res,
        error: i18n.t('errors.minLengthError', {minLength: minLength + 1})
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value.length < maxLength
    return {
        valid: res,
        error: i18n.t('errors.maxLengthError', {minLength: maxLength})
    }
}

const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: i18n.t('errors.emailError')
    }
}

const validateVatNumber = (value) => {
    const emailRegex = /^\d{8}-\d-\d{2}$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: i18n.t('errors.vatNumberError')
    }
}

const validateNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: value ? res : true,
        error: i18n.t('errors.numberError')
    }
}

const validateNonNegativeNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: value ? res && parseInt(value) >= 0 : true,
        error: i18n.t('errors.nonNegativeError')
    }
}

const validateRequired = (value) => {
    return {
        valid: Boolean(value),
        error: i18n.t('errors.requiredError')
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        valid: equal === value,
        error: 'Nem egyezik meg a két jelszó'
    }
}

const validateDate = (value) => {
    const isValidDate = (date) => {
        if (typeof date === 'string') {
            const parsedDate = new Date(date);
            // Check if the parsed date is valid and if the original string was a valid date format
            return !isNaN(parsedDate) && parsedDate.toISOString().startsWith(date);

        } else if (date instanceof Date) {
            return !isNaN(date);
        }
        return false;
    };
    return {
        valid: isValidDate(value),
        error: i18n.t('errors.dateError')
    }
}


const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        valid: isValid,
        error: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot'
    }
}

export {
    validateChars,
    validateMinLength,
    validateEmail,
    validateRequired,
    validateEqualsPassword,
    validatePassword,
    validateMaxLength,
    validateNumber,
    validateVatNumber,
    validateDate,
    validateNonNegativeNumber
}