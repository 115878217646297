import React from 'react';
import { Card, CardActionArea, CardMedia, Typography, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Overlay = styled(Box)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const HoverText = styled(Typography)({
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
});

const HoverBodyText = styled(Typography)({
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
});

const StyledCard = styled(Card)({
    position: 'relative',
    '&:hover': {
        '& .MuiCardMedia-root': {
            filter: 'blur(5px)',
        },
        '& .overlay': {
            opacity: 1,
        },
    },
});

const DateOverlay = styled(Box)({
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '4px',
    fontSize: '12px',
});

const ImageCard = ({ image, title, link, description, onClick, fromDate, endDate, htmlDescription = null }) => {
    const isExternal = link?.startsWith('http');
    const LinkComponent = link ? (isExternal ? 'a' : Link) : 'div';

    const cardContent = (
        <>
            <CardMedia
                component="img"
                height="200"
                image={image}
                alt={title}
            />
            {(fromDate || endDate) && (
                <DateOverlay>
                    {fromDate && endDate ? `${fromDate} - ${endDate}` : fromDate || endDate}
                </DateOverlay>
            )}
            <Box padding={2}>
                <Typography variant="h6" component="div" gutterBottom>
                    {title}
                </Typography>
                {description && (
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                )}
                {htmlDescription && (
                    <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: htmlDescription }} />
                )}
            </Box>
        </>
    );

    return (
        <Card>
            {link || onClick ? (
                <CardActionArea
                    component={LinkComponent}
                    to={link}
                    href={link}
                    onClick={onClick}
                >
                    {cardContent}
                </CardActionArea>
            ) : (
                <Box>
                    {cardContent}
                </Box>
            )}
        </Card>
    );
};

export default ImageCard;
