import {TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
// import AdapterDateFns from "@mui/x-date-pickers-pro/AdapterDateFns";
import locale from "date-fns/locale/hu";
import React from "react";
import {formatHuman} from "../../utils/utils";
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';


const CustomDatePicker = ({errors, setErrors, data, setData, name, validators, ...rest}) => {

    const handleChange = (newValue) => {
        setData({...data, [name]: newValue});
        if (rest?.sideEffect) rest.sideEffect(newValue)
    };

    const handleBlur = (newValue) => {
        for (let validator of validators[name]) {
            const {valid, error} = validator(newValue)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    };

    return <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DatePicker
            value={data[name]}
            onChange={e => {
                handleBlur(e)
                handleChange(e)
            }}
            onBlur={handleBlur}
            {...rest}
            InputAdornmentProps={{ position: 'start' }}
            renderInput={params => <TextField error={Boolean(errors[name])} helperText={errors[name] && errors[name]} id={name} InputAdornmentProps={{ position: 'start' }} name={name} {...params} />}
        />
    </LocalizationProvider>
}

export default CustomDatePicker