import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";

const Complaints = () => {
    const text = 'A Jet Travel Kft. (a továbbiakban: „Jet Travel”) befogad, nyilvántartásba vesz és kivizsgál minden, a szolgáltatásával kapcsolatos panaszt. A Jet Travel a panaszokat jellegük szerint nyilvántartásba veszi, teljes körűen kivizsgálja és megválaszolja.\n' +
        '\n' +
        '\n' +
        '1. A PANASZ BEJELENTÉSÉNEK MÓDJAI\n' +
        '\n' +
        'A Jet Travel biztosítja, hogy az ügyfelek panaszaikat szóban vagy írásban közölhessék az alábbiak szerint.\n' +
        '\n' +
        'Szóbeli panasz\n' +
        'a) személyesen a Jet Travel székhelyén a nyitvatartási idő alatt:\n' +
        'Székhely: 1113 Budapest, Bocskai út 77-79. B. ép. 3. em.\n' +
        'Nyitva tartás: hétfő-péntek 9:00-17:30\n' +
        'b) telefonon a Jet Travel központi számán\n' +
        'Telefon: +36 1 209 2110\n' +
        '\n' +
        'Írásbeli panasz\n' +
        'a) személyesen vagy más által átadott irat útján a Jet Travel székhelyén, nyitvatartási idő alatt:\n' +
        'Székhely: 1113 Budapest, Bocskai út 77-79. B. ép. 3. em.\n' +
        'Nyitva tartás: hétfő-péntek 9:00-17:30\n' +
        'b) postai úton (a Jet Travel székhelyére)\n' +
        'c) telefaxon (+36 1 209 2111)\n' +
        'd) elektronikus levélben (e-mail: jettravel@jettravel.hu)\n' +
        '\n' +
        '\n' +
        '2. A PANASZ KIVIZSGÁLÁSA\n' +
        '\n' +
        'A Jet Travel a szóbeli panaszt azonnal megvizsgálja, és szükség szerint orvosolja. Ha az ügyfél a panasz kezelésével nem ért egyet, vagy a panasz azonnali kivizsgálása nem lehetséges, a Jet Travel a panaszról és az azzal kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet vesz fel, és annak egy másolati példányát\n' +
        'a) személyesen közölt szóbeli panasz esetén helyben az ügyfélnek átadja,\n' +
        'b) telefonon vagy egyéb elektronikus hírközlési szolgáltatás felhasználásával közölt szóbeli panasz esetén az ügyfélnek legkésőbb az érdemi válaszával egyidejűleg megküldi.\n' +
        '\n' +
        'A panasz kivizsgálása az összes vonatkozó körülmény figyelembevételével történik. A Jet Travel tájékoztatja az ügyfeleket, hogy a panasz kivizsgálását nagyban megkönnyíti, ha az ügyfél a panaszát fényképekkel, dokumentumokkal (lehetőség szerint a helyszínen, csoportkísérő vagy – annak hiányában – a szolgáltató által felvett jegyzőkönyvvel) tudja alátámasztani.\n' +
        '\n' +
        'Az ügyfél bejelentésében rendelkezhet arról, hogy milyen formában vár választ megkeresésére. \n' +
        '\n' +
        'A Jet Travel munkatársai a panaszokat kivizsgálják, és azokat lehetőség szerint orvosolják.\n' +
        '\n' +
        'A beérkező panaszokról minden esetben jegyzőkönyv készül, amely legalább a következő adatokat, nyilatkozatokat rögzíti:\n' +
        'a) az ügyfél neve,\n' +
        'b) az ügyfél lakcíme, székhelye,\n' +
        'c) a panasz előterjesztésének helye, ideje, módja,\n' +
        'd) az ügyfél panaszának részletes leírása,\n' +
        'e) az ügyfél által bemutatott iratok, dokumentumok, és egyéb bizonyítékok jegyzéke,\n' +
        'f) a Jet Travel nyilatkozata az ügyfél panaszával kapcsolatos álláspontjáról, amennyiben a panasz azonnali kivizsgálása lehetséges,\n' +
        'g) a jegyzőkönyvet felvevő személy neve,\n' +
        'h) a jegyzőkönyvet felvevő személy és – telefonon vagy egyéb elektronikus hírközlési szolgáltatás felhasználásával közölt szóbeli panasz kivételével – az ügyfél aláírása,\n' +
        'i) a jegyzőkönyv felvételének helye, ideje,\n' +
        'j) a jegyzőkönyv sorszáma.\n' +
        '\n' +
        'A Jet Travel a beérkezett panaszokra 30 napon belül írásban érdemi választ ad, amelyben a Jet Travel a panasznak részben vagy egészben helyt ad, és intézkedik annak orvoslása érdekében; avagy a panaszt megfelelő indokolás mellett elutasítja.\n' +
        '\n' +
        'Amennyiben a bejelentés nem tartalmaz elegendő információt a panasz érdemi kivizsgálásához, a Jet Travel haladéktalanul megkeresi a bejelentőt a hiányzó adatok pótlása érdekében.\n' +
        '\n' +
        '\n' +
        '3. A PANASZKEZELÉSHEZ FŰZŐDŐ TÁJÉKOZTATÁSI KÖTELEZETTSÉG, JOGORVOSLATI LEHETŐSÉGEK\n' +
        '\n' +
        'Amennyiben az ügyfél a panaszra kapott válasszal nem ért egyet, esetleg új információval vagy dokumentációval rendelkezik, kérheti a Jet Traveltől az 1. pontban megjelölt elérhetőségek valamelyikén a panasszal kapcsolatos döntés felülvizsgálatát. Ebben az esetben a Jet Travel ismételten megvizsgálja a panaszt, és a bejelentést új panaszként kezeli.\n' +
        '\n' +
        'A fogyasztóvédelmi szabályok megsértése esetén – amennyiben a fenti panaszkezelés eredménytelenül zárult le és az egyéb jogszabályi feltételek is fennállnak – az ügyfél panaszával a területileg illetékes fogyasztóvédelmi hatósághoz fordulhat. Fogyasztóvédelmi hatóságként a kormányhivatalok járnak el, amelyek elérhetőségei megtalálhatóak az alábbi linken: https://kormanyhivatalok.hu.\n' +
        '\n' +
        'Az ügyfél fogyasztói jogvita esetén az ügyfél lakóhelye vagy tartózkodási hely szerint illetékes, az alábbiakban felsorolt valamely békéltető testülethez is fordulhat.\n' +
        '\n' +
        'Baranya Vármegyei Békéltető Testület \n' +
        'Székhely és levelezési cím: 7625 Pécs, Majorossy Imre u. 36.\n' +
        'Telefon: +36 72 507 154, +36 20 283 3422\n' +
        'Email: info@baranyabekeltetes.hu vagy kerelem@baranyabekeltetes.hu \n' +
        'Honlap: www.baranyabekeltetes.hu \n' +
        'Hivatali kapu azonosító: 667360112\n' +
        'Rövid neve: PBKIKBT \n' +
        '\n' +
        'Borsod-Abaúj-Zemplén Vármegyei Békéltető Testület\n' +
        'Székhely: 3525 Miskolc, Szentpáli u. 1.\n' +
        'Levelezési cím: 3501 Miskolc, Pf.  376.\n' +
        'Telefon: +36 46 501 090\n' +
        'Email: bekeltetes@bokik.hu \n' +
        'Honlap: www.bekeltetes.borsodmegye.hu \n' +
        'Hivatali kapu azonosító: 466467335\n' +
        'Rövid név: BOKIKBT\n' +
        '\n' +
        'Budapesti Békéltető Testület\n' +
        'Székhely: 1016 Budapest, Krisztina krt. 99.\n' +
        'Levelezési cím: 1253 Budapest, Pf. 10.\n' +
        'Telefon: +36 1 488 2131\n' +
        'E-mail: bekelteto.testulet@bkik.hu \n' +
        'Honlap: www.bekeltet.bkik.hu \n' +
        'Hivatali kapu azonosító: 469532362\n' +
        'Rövid név: BBT\n' +
        '\n' +
        'Csongrád-Csanád Vármegyei Békéltető Testület\n' +
        'Székhely és levelezési cím: 6721 Szeged, Párizsi krt. 8-12.\n' +
        'Telefon: +36 62 554 250 / 118-as mellék\n' +
        'Email: bekelteto.testulet@cskik.hu \n' +
        'Honlap: www.bekeltetes-csongrad.hu\n' +
        'Hivatali kapu azonosító: 162127371\n' +
        'Rövid név: CSMKIKBT\n' +
        '\n' +
        'Fejér Vármegyei Békéltető Testület\n' +
        'Székhely és levelezési cím: 8000 Székesfehérvár, Hosszúséta tér 4-6.\n' +
        'Telefon: +36 22 510 310\n' +
        'Email: bekeltetes@fmkik.hu \n' +
        'Honlap: www.bekeltetesfejer.hu\n' +
        'Hivatali kapu azonosító: 352258915\n' +
        'Rövid név: FMBT\n' +
        '\n' +
        'Győr-Moson-Sopron Vármegyei Békéltető Testület\n' +
        'Székhely és levelezési cím: 9021 Győr, Szent István út 10/a.\n' +
        'Telefon: +36 96 520 217\n' +
        'Email: bekelteto.testulet@gymsmkik.hu \n' +
        'Honlap: www.bekeltetesgyor.hu/hu \n' +
        'Hivatali kapu azonosító: 363053930\n' +
        'Rövid név: GYMSMKIKBT\n' +
        '\n' +
        'Hajdú-Bihar Vármegyei Békéltető Testület\n' +
        'Székhely: 4025 Debrecen, Petőfi tér 10.\n' +
        'Ügyintézés helyszíne és levelezési cím: 4025 Debrecen, Vörösmarty u. 13-15.\n' +
        'Telefon: +36 52 500 710, +36 52 500 745\n' +
        'Fax: +36 52 500 720\n' +
        'Email: bekelteto@hbkik.hu \n' +
        'Honlap: www.hbmbekeltetes.hu \n' +
        'Hivatali kapu azonosító: 457289758\n' +
        'Rövid név: HBKIKBT\n' +
        '\n' +
        'Pest Vármegyei Békéltető Testület\n' +
        'Székhely: 1055 Budapest, Balassi Bálint utca 25. IV. emelet 2.\n' +
        'Levelezési cím: 1364 Budapest, Pf. 81\n' +
        'Telefon: +36 1 792 7881\n' +
        'Fax: +36 1 792 7881\n' +
        'Email: pmbekelteto@pmkik.hu \n' +
        'Honlap: www.panaszrendezes.hu vagy www.pestmegyeibekelteto.hu \n' +
        'Hivatali kapu azonosító: 560351920 \n' +
        'Rövid név: PMKIKBEKEL\n' +
        '\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>Panaszkezelési szabályzat</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default Complaints