import {Box, Button, Container, Grid, Typography} from "@mui/material";
import useScrollToTop from "../hooks/useScrollToTop";
import useCallDataApi from "../hooks/data";
import {useContext, useEffect, useState} from "react";
import HeroImage from "../components/HeroImage";
import EmployeeAvatar from "../components/EmployeeAvatar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ConfigContext from "../context/ConfigContext";
import mainImage from '../assets/csapatunk.jpg'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";
import ImageStack from "../components/ImageStack";


const Employees = () => {

    useScrollToTop()
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('colleagues.title')}`
    const {language} = useContext(ConfigContext)
    const history = useHistory()

    const {getData: fetchEmployees} = useCallDataApi('colleague');
    const {getData: fetchBranches} = useCallDataApi('branch');
    const [employees, setEmployees] = useState([]);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        Promise.all([fetchEmployees(), fetchBranches()])
            .then(([employees, branches]) => {
                setEmployees(employees);
                setBranches(branches);
            })
            .finally(() => setLoading(false));
    }, []);

    return <DrawerAppbar fade>
        <HeroImage
            description={t('colleagues.description')}
            title={t('colleagues.title')}
            underlineColor="#ff0000"
            image={mainImage}
        />
        <Container style={{marginTop: '64px', marginBottom: '64px'}}>
            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>
        </Container>
        <ImageStack images={branches?.map(branch => ({image: branch?.image, title: branch?.translations[language]?.name, description: branch?.translations[language]?.summary, link: `/colleagues/${branch?.id}`}))}/>
        <Box sx={{marginTop: 10}}/>
        <Footer/>
        <Loading isLoading={loading}/>
    </DrawerAppbar>
};

export default Employees;
