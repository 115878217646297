import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import {useHistory} from "react-router-dom";
import jetLogo from '../assets/shared/jet-travel-logo-white.svg'
import {Drawer, FormControl, ListItemIcon, ListItemText, Select, Stack, useTheme} from "@mui/material";
import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import menuItems from "../utils/menuItems";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Flag from 'react-world-flags';


function TransparentAppBar({fade}) {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [scrollHeight, setScrollHeight] = useState(0)
    const [language, setLanguage] = useState('en');
    const history = useHistory()
    const theme = useTheme()

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const toggleNavMenu = (event) => {
        setAnchorElNav(!Boolean(anchorElNav) ? event.currentTarget : null);
    };

    const menuIconStyle = {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30px',
        color: 'white',
    }

    const scrollWithOffset = (el) => {
        setTimeout(() => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -50;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 500)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", scrollListener)
    }, [])

    const scrollListener = (event) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setScrollHeight(winScroll)
    }

    return (
        <><AppBar position="fixed" style={(fade && scrollHeight < 400) ? {backgroundColor: 'transparent', transition: 'background-color 0.5s ease', color: 'white'} : {backgroundColor: theme.palette.primary[400], transition: 'background-color 0.5s ease', color: 'white'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={jetLogo} alt="Jet Travel logo" style={{cursor: 'pointer', padding: '10px'}} width={100} onClick={() => history.push('/')}/>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, gap: 4}}>
                        {menuItems.map(p => <HashLink className='nav-button' key={`b_${p.name}`} to={p.url} scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="body1" color='#fff'>{p.name}</Typography>
                        </HashLink>)}
                        {/*<Button onClick={() => window.scrollTo(0, 0)}>scroll top</Button>*/}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Stack direction='row' sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton color="inherit" href="https://facebook.com">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton color="inherit" href="https://twitter.com">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton color="inherit" href="https://instagram.com">
                                <InstagramIcon />
                            </IconButton>
                        </Stack>
                        <FormControl variant="standard" sx={{ ml: 2, minWidth: 80 }}>
                            <Select
                                id="language-selector"
                                value={language}
                                onChange={handleLanguageChange}
                                sx={{ display: { xs: 'block', md: 'block' } }}
                            >
                                <MenuItem value="en">
                                    <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                        {/*<ListItemIcon>*/}
                                        {/*    <Flag code="US" style={{ width: 24, height: 16 }} />*/}
                                        {/*</ListItemIcon>*/}
                                        <ListItemText primary="EN" />
                                    </Stack>
                                </MenuItem>
                                <MenuItem value="hu">
                                    <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                        {/*<ListItemIcon>*/}
                                        {/*    <Flag code="HU" style={{ width: 24, height: 16 }} />*/}
                                        {/*</ListItemIcon>*/}
                                        <ListItemText primary="HU" />
                                    </Stack>
                                </MenuItem>
                                <MenuItem value="es">
                                    <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                        {/*<ListItemIcon>*/}
                                        {/*    <Flag code="ES" style={{ width: 24, height: 16 }} />*/}
                                        {/*</ListItemIcon>*/}
                                        <ListItemText primary="ES" />
                                    </Stack>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                        <div style={menuIconStyle}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleNavMenu}
                                color="inherit"
                            >
                                {!anchorElNav ? <MenuIcon/> : <CloseIcon/>}
                            </IconButton>
                        </div>
                        <Drawer
                            anchor={'top'}
                            open={Boolean(anchorElNav)}
                            onClose={toggleNavMenu}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            BackdropProps={{invisible: true}}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    height: '80%',
                                    // marginTop: '95px'
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '10'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                margin: 'auto',
                                gap: '48px'
                            }}>
                                {menuItems.map((page) => (
                                    <MenuItem key={page.name + 'mobile'} onClick={handleCloseNavMenu}>
                                        <HashLink to={page.url} scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none', color: theme.palette.primary[600]}}>
                                            <Typography textAlign="center" variant="body1" style={{fontSize: '150%'}}>{page.name}</Typography>
                                        </HashLink>
                                    </MenuItem>
                                ))}
                            </div>

                        </Drawer>

                    </Box>


                </Toolbar>
            </Container>
        </AppBar>
            {!fade && <Toolbar/>}
        </>
    );
}

export default TransparentAppBar;