import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";

const TravelPolicy = () => {
    const text = 'ÁLTALÁNOS UTAZÁSI FELTÉTELEK\n' +
        '\n' +
        '- utazási csomagok esetén -\n' +
        '\n' +
        '\n' +
        '\n' +
        'I. BEVEZETŐ RENDELKEZÉSEK\n' +
        '\n' +
        '1. A „Jet Travel” Kft. (székhely: 1113 Budapest, Bocskai út 77-79. B. ép. 3. em., cégjegyzékszám: 01-09-063771, nyilvántartásba vételi szám: R-0334/1992, telefonszám: +36 1 2092110, a továbbiakban: Jet Travel) által szervezett utazásokra utazási szolgáltatásokra vonatkozó szerződésekről, különösen az utazási csomagra és az utazási szolgáltatásegyüttesre vonatkozó szerződésekről 472/2017.(XII.28.) Kormányrendelet, az utazásszervező és -közvetítő tevékenységről szóló 213/1996. (XII. 23.) Kormányrendelet (a továbbiakban: „Utevr.”), a Polgári Törvénykönyvről szóló 2013. évi V. törvény – különösen annak az utazási szerződésről rendelkező 6:254 §-a –, továbbá az Utazási Szerződésben foglaltak az irányadóak.\n' +
        '\n' +
        '2. A jelen Általános Utazási Feltételekben nagybetűvel írt fogalmak az alábbi jelentéstartalommal bírnak:\n' +
        '\n' +
        'Felek : az Utas és a Jet Travel együttesen.\n' +
        '\n' +
        'Utazási Szerződés : olyan szerződés, amely alapján a Jet Travel a 472/2017.(XII.28.) Kormányrendelet szerinti utazási csomagot ad el az Utas részére, az Utas pedig díj fizetésére köteles. Az Utazási Szerződés az Egyedi Utazási Feltételekből, az Általános Utazási Feltételekből, valamint valamennyi egyéb, mellékletként csatolt dokumentumból (így különösen Útleírás, Tájékoztató és Speciális Utazási Feltételek) áll.\n' +
        '\n' +
        'Utas : az a természetes személy, jogi személy vagy jogi személyiséggel nem rendelkező szervezet, amely a Jet Travel szolgáltatásainak igénybevétele érdekében a Jet Travellel Utazási Szerződést köt.\n' +
        '\n' +
        '\n' +
        'II. AZ UTAZÁSI SZERZŐDÉS LÉTREJÖTTE\n' +
        '\n' +
        '1. Az Utazási Szerződés akkor jön létre, amikor a Felek aláírják az Egyedi Utazási Feltételeket.\n' +
        '2. Ha a jelentkezést helyhiány, vagy más ok miatt a Jet Travel csak feltételesen fogadta el, az Utazási Szerződés csak akkor lép hatályba, ha az adott feltétel teljesül. Amennyiben az adott feltétel nem teljesül, úgy az Utazási Szerződés nem lép hatályba, és a Jet Travel az előleget az Utas részére visszafizeti.\n' +
        '3. A Jet Travel által nyújtott szolgáltatások részleteit és adatait (így különösen azok időpontját, helyét, díját, az igénybevétel feltételeit) a Felek által aláírt Egyedi Utazási Feltételek és/vagy a mellékletként csatolt Útleírás tartalmazza.\n' +
        '\n' +
        '\n' +
        'III. RÉSZVÉTELI DÍJ\n' +
        '\n' +
        '1. Az Utas az Egyedi Utazási Feltételek aláírásával egyidejűleg a Jet Travelnek a részvételi díj 40%-át mint előleget megfizeti. A Felek ennél magasabb összegű előlegben is megállapodhatnak az Egyedi Utazási Feltételekben, ha a valamely közreműködővel kötött szerződés ennél szigorúbb kötelezettséget ró a Jet Travelre.\n' +
        '2. Az Utasnak az utazás megkezdése előtt 30 (harminc) nappal a teljes részvételi díjhátralékát ki kell egyenlítenie, kivéve, ha a Jet Travel és a külföldi közreműködő közötti szerződés alapján ennél korábbi teljesítésre van szükség. Erről a Jet Travel tájékoztatja az Utast. Ugyanezen időpontban esedékes a jelentkezéskor megrendelt kapcsolódó szolgáltatások díjának kiegyenlítése (fakultatív programok, balesetbiztosítás stb.) is a Jet Travel által kiállított számla ellenében.\n' +
        '\n' +
        '3. A Jet Travel a részvételi díjhátralék esedékességéről külön értesítést nem küld. Az Utas tudomásul veszi, hogy a díjhátralék fizetési határidejének eredménytelen lejárta után a Jet Travel az Utazási Szerződést jogosult egyoldalúan megszüntetni, abban az esetben is, ha a foglalást már a Jet Travel az Utas részére visszaigazolta.\n' +
        '4. Amennyiben a jelentkezés az utazást megelőző 30 (harminc) napon belül történik, a fenti díjak teljes összegét a jelentkezéskor kell befizetni.\n' +
        '\n' +
        '5. A Jet Travel nem köteles a részvételi díjat leszállítani, ha az Utas valamely szolgáltatást saját elhatározásából vagy az érdekkörében felmerült okból nem vett igénybe.\n' +
        '\n' +
        '\n' +
        'IV. MEGNÖVEKEDETT KÖLTSÉGEK\n' +
        '\n' +
        '1. A Jet Travel kifejezetten fenntartja azon jogát, hogy az Utas által az Utazási Szerződés alapján fizetendő díjat legkésőbb az utazás megkezdése előtt 20 (húsz) nappal a költségnövekedés mértékével arányosan megváltoztassa, amennyiben:\n' +
        '\n' +
        '- az üzemanyag vagy egyéb energiaforrás költségei miatt a személyszállítás árának\n' +
        '\n' +
        '- az Utazási Szerződésben vállalt részszolgáltatásokkal kapcsolatos, a teljesítésben közvetlenül részt nem vevő harmadik felek által kivetett adó, illeték és egyéb kötelező terhek (így különösen üdülőhelyi díj, horgonyzási díj, repülőtéri illeték), vagy\n' +
        '\n' +
        '- az adott utazási szolgáltatások esetében irányadó devizaárfolyamok\n' +
        '\n' +
        'változása ezt a Jet Travel részéről indokolttá teszi.\n' +
        '\n' +
        '2. A díjemelés indokát a Jet Travel az Utassal a díjemelés közlésével egyidejűleg tartós adathordozó eszközön közli. A Jet Travel a részvételi díjat oly módon emeli, hogy az eredetileg befizetett részvételi díjat arányosan megemeli a fenti okokból megemelkedett költségek összegével. A Jet Travel ennek megfelelően az eredetileg befizetett részvételi díj és a megemelt részvételi díj különbözetéről számlát állít ki az Utas részére.\n' +
        '\n' +
        '3. Amennyiben a fentiekben részletezett díjemelés mértéke a 8%-ot meghaladja, a Jet Travel az Utast erről haladéktalanul értesíti, és az Utas az értesítés kézhezvételétől számított 3 (három) napon belül választása szerint írásban felmondhatja az Utazási Szerződést, vagy elfogadhatja a díjemelést. Amennyiben az Utas elfogadja a díjemelést, úgy a Felek írásbeli megállapodásukkal módosítják az Utazási Szerződést. Amennyiben az Utas felmondja az Utazási Szerződést, a Jet Travel visszafizeti az addig befizetett részvételi díjat az Utasnak és a Jet Travel bánatpénzre nem jogosult. Az Utas jelen pont szerinti felmondása esetén a Jet Travel minden tőle telhetőt megtesz annak érdekében, hogy az Utas részére azonos vagy magasabb minőségű utazási csomagot ajánljon fel.\n' +
        '\n' +
        '4. A fenti IV/3. pont rendelkezései megfelelően irányadóak abban az esetben is, ha a Jet Travel megváltoztatja az Utazási Szerződés valamely lényeges elemét, vagy nem tudja teljesíteni valamely, az Utas által korábban jelzett és a Jet Travel által kifejezetten elfogadott különleges igényt.\n' +
        '\n' +
        '5. Amennyiben a fenti IV/1. pontban foglalt bármely költség az Utazási Szerződés megkötését követően, de az utazási csomag megkezdése előtt csökken, úgy az Utas megfelelő díjengedményre jogosult, amely esetben azonban az Utasnak visszafizetendő összegből a Jet Travel levonhatja ténylegesen felmerült és kérés esetén megfelelően igazolt adminisztratív költségeit.\n' +
        '\n' +
        '\n' +
        'V. AZ UTAS FELMONDÁSA\n' +
        '\n' +
        '1. Az Utas az Utazási Szerződést az utazás megkezdése előtt írásban bármikor felmondhatja. Ha az Utas az utazás megkezdése előtt 35 (harmincöt) napon belül az Utazási Szerződést a saját érdekkörében felmerült okból felmondja, bánatpénz fizetésére köteles az alábbiak szerint. Ha a felmondás az utazás előtti\n' +
        '\n' +
        '- 35 - 30. napon történik, a részvételi díj 50%-át,\n' +
        '\n' +
        '- 29 - 0. napon történik, a részvételi díj 100%-át, kell az Utasnak bánatpénzként megfizetnie a Jet Travel részére.\n' +
        '\n' +
        'Az Utas akkor is köteles a bánatpénzt a fentiek szerint megfizetni, ha az utazáshoz szükséges hatósági engedélyek hiánya, illetve a hatóság kizáró határozata miatt nem tud az utazáson részt venni.\n' +
        '2. A Jet Travel nem követelhet bánatpénzt az Utastól az Utas IV/3. és IV/4. pont szerinti felmondása esetén.\n' +
        '\n' +
        '3. Az utazási csomag megkezdését megelőzően az Utas bánatpénz fizetése nélkül jogosult felmondani az Utazási Szerződést, ha a célállomás helyén vagy annak közvetlen közelében, - amennyiben a célállomás, vagy annak közvetlen környezetében a külpolitikáért felelős miniszter által vezetett minisztérium honlapján az „utazásra nem javasolt” utazási célországokat és térségeket megjelölő felsorolásba felvételre került – olyan elháríthatatlan és rendkívüli körülmények merülnek fel, amelyek az utazási csomag teljesítését jelentős mértékben befolyásolják, vagy lényegesen befolyásolják az utazók célállomásra való szállítását. Az Utazási Szerződés e bekezdés szerinti felmondása esetén az Utas az utazási csomag ellenértékeként befizetett teljes díj visszafizetésére jogosult, további kártérítést azonban nem követelhet.\n' +
        '\n' +
        '4. A felmondási szándékát az Utasnak személyesen, vagy írásban, ajánlott levélben kell jeleznie a Jet Travel részére.\n' +
        '5. A felmondás időpontja alatt azt az időpontot kell érteni, amikor a felmondás ténye a Jet Travel tudomására jut a fenti V/3. pontban meghatározott módon.\n' +
        '\n' +
        'VI. A JET TRAVEL FELMONDÁSA\n' +
        '\n' +
        '1. A Jet Travel kártérítési kötelezettség nélkül jogosult felmondani az Utazási Szerződést és köteles visszafizetni az Utasnak az utazási csomag ellenértékeként befizetett teljes díjat, ha\n' +
        '\n' +
        'a) az utazási csomagra jelentkezett személyek száma nem éri el az Utazási Szerződésben meghatározott minimális létszámot és a Jet Travel az Utazási Szerződés felmondásáról ésszerű határidőn belül, de legkésőbb az alábbiak szerint értesíti az Utast:\n' +
        '\n' +
        '- húsz nappal az utazási csomag megkezdése előtt a hat napnál hosszabb utak esetén,\n' +
        '\n' +
        '- hét nappal az utazási csomag megkezdése előtt a kettő és hat nap közötti időtartamú utak esetén,\n' +
        '\n' +
        '- negyvennyolc órával az utazási csomag megkezdése előtt, a két napnál rövidebb utak esetén, vagy\n' +
        '\n' +
        'b) a Jet Travelt elháríthatatlan és rendkívüli körülmények (a továbbiakban: vis maior) gátolják az Utazási Szerződés teljesítésében, és indokolatlan késedelem nélkül, az utazási csomag megkezdése előtt értesíti az Utast az Utazási Szerződés felmondásáról.\n' +
        '\n' +
        'VII. HIBÁS TELJESÍTÉS\n' +
        '\n' +
        '1. Az Utazási Szerződésben vállalt utazási szolgáltatás teljesítéséért a Jet Travel felel, akkor is, ha az utazási szolgáltatások teljesítésére más utazási szolgáltató köteles.\n' +
        '\n' +
        '2. Az Utas az eset körülményeinek figyelembevételével köteles haladéktalanul – az Utazási Szerződésben rögzített elérhetőségek valamelyikén – tájékoztatni a Jet Travelt az Utazási Szerződésben meghatározott valamely utazási szolgáltatás nyújtása során általa észlelt szerződésszegésről.\n' +
        '\n' +
        '3. A szerződésszegést a Jet Travel köteles orvosolni, kivéve, ha (i) az lehetetlen, vagy (ii) aránytalan költségekkel jár a szerződésszegés súlyára és az érintett utazási szolgáltatások értékére figyelemmel. Amennyiben a Jet Travel a jelen pontban megjelölt valamely ok miatt nem orvosolja a szerződésszegést, az Utas díjengedményre, illetve kártérítésre jogosult.\n' +
        '\n' +
        '4. Amennyiben a Jet Travel – a fenti 3. pontban megjelölt okok kivételével – az Utas által megállapított ésszerű határidőn belül nem orvosolja a szerződésszegést, az Utas ezt maga is megteheti, és kérheti a szükséges kiadások megtérítését. Az Utas határidő tűzése nélkül jogosult a szerződésszegés orvoslására, ha a Jet Travel azt megtagadja, vagy azonnali beavatkozásra van szükség.\n' +
        '\n' +
        '5. Amennyiben valamely utazási szolgáltatás lényeges része az Utazási Szerződésben meghatározott módon nem biztosítható, a Jet Travel köteles az Utas részére többletköltség felszámítása nélkül, lehetőség szerint az Utazási Szerződésben meghatározottakkal azonos vagy magasabb minőségű, megfelelő helyettesítő szolgáltatást felajánlani az utazás folytatására, ideértve azt is, amikor az Utast nem tudja az Utazási Szerződésnek megfelelően visszajuttatni az indulási helyre. Az Utas kizárólag akkor utasíthatja el a felajánlott helyettesítő szolgáltatást, ha az lényegesen különbözik az Utazási Szerződésben foglaltaktól, illetve ha – alacsonyabb minőségű helyettesítő szolgáltatás felajánlása esetén – a Jet Travel által ajánlott díjengedmény nem megfelelő.\n' +
        '\n' +
        '6. Amennyiben a szerződésszegés az Utazási Szerződés lényeges tartalmát érinti, és a Jet Travel az Utas által megszabott ésszerű határidőn belül azt nem orvosolja, az Utas bánatpénz megfizetése nélkül jogosult az Utazási Szerződést felmondani, valamint díjengedményt, illetve kártérítést követelhet.\n' +
        '\n' +
        '7. Amennyiben a helyettesítő szolgáltatás nyújtása nem lehetséges, vagy azt az Utas a fentiek alapján elutasítja, az Utas akkor is jogosult díjengedményre, illetve kártérítésre, ha felmondási jogát nem gyakorolta.\n' +
        '\n' +
        '8. Amennyiben az utazási csomag az Utas szállítását is magában foglalja, a Jet Travel a fentieken túl haladéktalanul, az Utast terhelő többletköltség nélkül köteles az Utazási Szerződés szerinti közlekedési eszközzel gondoskodni az Utas hazaszállításáról.\n' +
        '\n' +
        '9. A Jet Travel az Útleírásban az Utas rendelkezésére bocsátja helyi képviselőjének, egy kijelölt kapcsolattartó pontnak vagy egyéb szolgáltatónak a nevét, székhelyét, telefonszámát, email-címét továbbá – ha rendelkezik ilyennek – faxszámát, annak érdekében, hogyha az Utas nehéz helyzetbe kerül, illetve az utazási csomaggal kapcsolatban általa észlelt szerződésszegésről panaszt kíván tenni, a Jet Travellel való gyors és hatékony kommunikáció biztosítva legyen.\n' +
        '\n' +
        'AZ Utas fentiek szerinti panaszát lehetőség szerint jegyzőkönyvbe kell foglalni. A Jet Travel felhívja az Utas figyelmét arra, hogy a kifogást tartalmazó jegyzőkönyv kizárólag az Utas panaszainak a rögzítésére és nem az esetleges hibáknak a Jet Travel általi elismerésére szolgál. A jegyzőkönyvbe foglalt tények, észrevételek, stb. nem jelentik az Utas panaszában foglaltaknak a Jet Travel általi elismerését és nem minősülnek jogról való lemondásnak.\n' +
        '\n' +
        '10. A Felek megállapodnak, hogy a Jet Travel által az Utazási Szerződés alapján esetlegesen fizetendő kártérítés maximális összegét a részvételi díj háromszorosának megfelelő összegben korlátozzák, ide nem értve a személyi sérülés, illetve a szándékosan vagy súlyos gondatlanságból okozott kár eseteit.\n' +
        '\n' +
        '\n' +
        'VIII. A SZOLGÁLTATÁSOK MÓDOSÍTÁSA\n' +
        '\n' +
        '\n' +
        '1. A Jet Travel az általa vállalt utazási szolgáltatás megfelelő teljesítése érdekében fenntartja a jogát az Utazási Szerződésben rögzített közlekedési, utazási, vendéglátási, szállodai (szálláshely) vagy egyéb utazási szolgáltatások minőségének azonos vagy magasabb értékű szolgáltatásokkal való felcserélésére, illetve megváltoztatására, valamint a programok azonos, vagy magasabb értékű programokkal való felcserélésére, illetve megváltoztatására.\n' +
        '\n' +
        '2. Amennyiben a fenti VIII/1. pont alapján nyújtott új szolgáltatások értéke a nem teljesített szolgáltatások értékét meghaladja, a költségkülönbözet az Utasra nem hárítható át.\n' +
        '\n' +
        'IX. LÉGI FUVAROZÁS\n' +
        '\n' +
        '1. Az Utas szállítása repülőgéppel is történhet. Az ilyen szolgáltatás teljesítésére a légi személyszállításra vonatkozó jogszabályok és szerződési feltételek az irányadóak.\n' +
        '\n' +
        '2. A légi fuvarozásra vonatkozó előírások értelmében a Jet Travel fenntartja magának a jogot az érkezési és indulási időpontok, a menetrend, az útvonal és a megadott légitársaság változtatására.\n' +
        '3. A repülőgépjárat kiesése, vagy technikai okból történő kimaradása esetén a Jet Travel nem köteles a szállítást autóbusz, vonat, hajó vagy más személyszállítási eszköz igénybevételével teljesíteni.\n' +
        '4. A repülőutakkal kapcsolatban a légitársaságok légi személyfuvarozásra vonatkozó valamennyi feltételét és szabályzatát az Utas az utazási szolgáltatás teljesítésével kapcsolatosan az Utazási Szerződés aláírásakor megismerte, és azt az Utazási Szerződés részeként magára nézve kötelezőnek fogadja el.\n' +
        '5. A Jet Travel tájékoztatja Utasait, hogy az úti csomag (poggyász) súlya általában chartergépen legfeljebb 15 kg, menetrendszerinti gépen legfeljebb 20 kg lehet, azonban ez az előírás egyes légitársaságoknál szigorúbb is lehet. Túlsúly csak menetrendszerinti gépen lehetséges, melyért az Utasnak a légitársaságok tarifája szerint külön díjat kell fizetnie.\n' +
        '\n' +
        '6. A Jet Travel felel az utazás során felhasználásra kerülő repülőjegyeknek a megrendelés szerinti utazásra történő felhasználásának az alkalmasságáért.\n' +
        '\n' +
        '7. A Jet Travel tájékoztatja az Utast arról, hogy a légitársaságok által biztosított repülőjegyek módosítására, lemondására az egyes légitársaságok által kiadott szabályok vonatkoznak, amelyek eltérnek a Jet Travel jelen Általános Utazási Feltételekben rögzített feltételeitől.\n' +
        '\n' +
        '8. A Jet Travel a repülőjárat késésével, törlésével, illetőleg a repülőúttal kapcsolatos bármilyen módosítással (légitársaság-változás, repülőgépcsere, közbeeső leszállás) kapcsolatosan felmerült kár esetére minden felelősségét kizárja. Ugyancsak kizárja felelősségét az Utas poggyászának a súlyával kapcsolatos légitársaságonként eltérő rendelkezésekből adódó, valamint a poggyász késéséből és elvesztéséből eredő kártérítési igények esetére.\n' +
        '\n' +
        '9. Az Utas köteles az utazáshoz szükséges személyes adatait az érvényes és az utazáshoz használt dokumentumban (útlevélben vagy személyi igazolványban) szereplővel megegyező módon megadni. A légitársaságok megtagadhatják a fuvarozást, amennyiben az azonosításra használt dokumentumban és a repülőjegyen eltérő adatok szerepelnek. A helytelen adatok megadásából származó károkért a Jet Travel nem vállal felelősséget.\n' +
        '\n' +
        '10. Az Utas tudomásul veszi, hogy a kiállított repülőjegyek egyes kuponjai, szakaszai csak a kiállított sorrendben, folytatólagosan használhatók fel. Amennyiben tehát az Utas oda-vissza jeggyel vagy több utazási szakasszal rendelkezik, és a jegy valamely szakaszát nem használja fel („no-show”), úgy a légitársaság automatikusan törli a jegyhez tartozó foglalást, és a további szakaszoknál megtagadja az Utas szállítását. A Jet Travel ilyen esetekben nem tartozik felelősséggel az Utas felé.\n' +
        '\n' +
        '11. A repülőjegyen szereplő indulási idő előtt általában legalább 2 órával meg kell jelenni a repülőtéren. Egyes repülőterek esetén ennél szigorúbb szabályok is előfordulhatnak. A járatra való bejelentkezés legkésőbbi megengedett időpontja légitársaságonként, repülőterenként és útvonalanként változhat. Ezen szabályok betartása az Utas kizárólagos felelőssége.\n' +
        '\n' +
        '12. Az Utas tudomásul veszi, hogy a Jet Travel által igénybe vett légi fuvarozók felelősségét adott esetben nemzetközi egyezmények, így különösen a Montrealban, 1999. május 28-án kelt, a nemzetközi légi fuvarozásra vonatkozó egyes jogszabályok egységesítéséről szóló nemzetközi egyezmény (Magyarországon kihirdette: 2005. évi VII. törvény), valamint a nemzetközi légi fuvarozásra vonatkozó 1929. évi varsói nemzetközi egyezmény (Magyarországon kihirdette: 1936. évi XXVIII. törvény) is korlátozhatja.\n' +
        '\n' +
        '\n' +
        'X. SZERZŐDÉSÁTRUHÁZÁS\n' +
        '\n' +
        '1. Az Utas az Utazási Szerződésből fakadó jogait és kötelezettségeit harmadik személyre átruházhatja, feltéve, hogy (i) az átruházás tényéről a Jet Travelt haladéktalanul, de legkésőbb az Utazási Szerződés szerinti utazási csomag megkezdését megelőző ésszerű határidőn belül tájékoztatja és (ii) a fentiek szerinti harmadik személy az Utazási Szerződésben meghatározott valamennyi feltételnek megfelel.\n' +
        '\n' +
        '2. Az Utas tudomásul veszi, hogy a repülőjegyek a legtöbb esetben nem átruházhatóak, így ebben az esetben semelyik harmadik személy nem felel meg az Utazási Szerződésben meghatározott feltételeknek, azaz az Utazási Szerződés a fentiek szerint nem átruházható.\n' +
        '\n' +
        '\n' +
        'XI. Titoktartás és adatvédelem\n' +
        '\n' +
        '1. A Jet Travel az Utasoknak az Utazási Szerződés teljesítése során tudomására jutott személyes adatait üzleti titokként, bizalmasan kezeli, azokat megőrzi, a vonatkozó adatvédelmi szabályokat betartja.\n' +
        '\n' +
        '2. Az Utas az Utazási Szerződés aláírásával hozzájárul, hogy a Jet Travel az Utazási Szerződés teljesítéséhez szükséges módon és mértékben személyes adatait harmadik személyek javára továbbítsa.\n' +
        '\n' +
        '3. Az Utazási szerződés elválaszthatatlan mellékletét képezi a Jet Travel Adatvédelmi tájékoztatója.\n' +
        '\n' +
        '1. Poggyászának őrizetéről, felügyeletéről az Utas köteles gondoskodni, kivéve, ha azt továbbszállítás vagy megőrzés céljából a Jet Travel vagy közreműködője (fuvarozó vállalat) átvette és annak tényét a fuvarozó vagy a Jet Travel igazolta.\n' +
        '2. A Jet Travel betegség-, baleset- és poggyászkár esetére – díjfizetés ellenében – az Utassal biztosítási szerződést köthet.\n' +
        '\n' +
        '3. Az Utas teljes felelősségének tudatában nyilatkozik az utazásban résztvevő kiskorúak születési dátumáról, amelyek feltételei az esetleges gyermekkedvezmények igénybevételének. A hiányos vagy a valóságnak nem megfelelő adatközlésből eredő többletköltségek és károk megtérítése az Utast terheli. Amennyiben szülő vagy törvényes képviselő kísérete nélkül kiskorú utazik szállást is tartalmazó Utazási Szerződés alapján, a Jet Travel külön tájékoztatást nyújt a kiskorúval vagy a kiskorú tartózkodási helyén tartózkodó, a kiskorúért felelős személlyel való közvetlen kapcsolat létesítésének módjáról.\n' +
        '4. A Jet Travel az Utast a jelentkezésekor tájékoztatja arról, hogy az utazáshoz szükséges okmányokat (vízum, stb.) a Jet Travel vagy az Utas maga szerzi be. A Jet Travel az Utast tájékoztatja az egyes adatszolgáltatási időpontokról. A határidőnek az Utas általi elmulasztása esetén az utazás az Utas érdekkörében felmerült okból meghiúsultnak tekintendő.\n' +
        '\n' +
        '5. Az utazáshoz szükséges dokumentumok rendelkezésre állása az Utas felelőssége. A nemzetközi előírások általában a visszaérkezés dátumát követő további 6 hónapos érvényességet írnak elő az okmányok (útlevél, személyi igazolvány) esetében. Az Utas minden esetben köteles megfelelően tájékozódni az átszállási város országához és a célországhoz tartozó vízumszabályokat illetően. A Jet Travel nem ellenőrzi és nem vállal felelősséget azért, ha az utazás a szükséges dokumentumok, vízum vagy egyéb utazási feltétel hiányában meghiúsul.\n' +
        '\n' +
        '6. Az Utas egyebekben is köteles az utazás során az utazásra vonatkozó (útlevél, vám, vízum, deviza, egészségügyi, stb.) (jog)szabályokat betartani és előzetesen tájékozódni, különösen, ha az Utas nem magyar állampolgár. Ez utóbbi körülményre az Utas köteles a Jet Travel figyelmét felhívni. Ezen szabályok megszegéséből és az előírások be nem tartásából eredő költségek és károk megfizetése az Utast terheli.\n' +
        '\n' +
        '7. Amennyiben az Utas az utazáshoz szükséges dokumentumok hiánya miatt nem tud elutazni, úgy az felmondásnak minősül, és az Utas az V/1. pontban foglaltak szerint bánatpénz megfizetésére köteles.\n' +
        '8. A Jet Travel kizárja a felelősségét továbbá minden, az Utas saját hibájából felmerült kár esetére, így különösen abban az esetben, ha az Utas lekési a járatot, vagy nem jelenik meg a Jet Travel által az utazás lebonyolítása érdekében megjelölt időben és helyen (pl. repülőtér, busz- vagy vasúti pályaudvar stb.).\n' +
        '\n' +
        '9. Amennyiben az Utas harmadik személy javára foglalja le az utat, a Jet Travel nem köteles vizsgálni, hogy az Utas a harmadik személy jogszerű képviselője-e. Ez esetben az utazás megkezdéséig az Utast terhelő kötelezettségek az Utast, azt követően pedig a harmadik személyt terhelik. Az Utas köteles az Egyedi Utazási Feltételekben megadni a Jet Travel szolgáltatásait ténylegesen igénybe vevő természetes személyek (résztvevők) adatait.\n' +
        '\n' +
        '10. Az utazás során az Utas által harmadik személynek okozott kárért közvetlenül az Utas tartozik felelősséggel. Amennyiben bármely harmadik személy az Utas által okozott kár miatt a Jet Travellel szemben közvetlenül érvényesít igényt, úgy az Utas vállalja, hogy a Jet Travelt teljes körűen kártalanítja és helyette helytáll.\n' +
        '\n' +
        '11. A Jet Travel köteles segítséget nyújtani az Utas számára, ha az az utazás során nehéz helyzetbe kerül. A Jet Travel a fentiek szerinti segítségnyújtásért arányos díjra jogosult, ha a nehéz helyzetet az Utas szándékosan vagy gondatlanságból maga idézte elő. E díj nem haladhatja meg a Jet Travel ténylegesen felmerült költségeit.\n' +
        '\n' +
        '\n' +
        'XIII. VEGYES RENDELKEZÉSEK\n' +
        '\n' +
        '1. Amennyiben a Jet Travel bármely okból nem teljesítené az Utas hazaszállítására, vagy az előleg, illetve a részvételi díj visszafizetésére vonatkozó kötelezettségét, akkor az Utas az Alfa Vienna Insurance Group Biztosító Zrt.-hez fordulhat igényének érvényesítése érdekében, amely a Jet Travel részére az Utevr. szerinti vagyoni biztosítékot nyújtja. Fizetésképtelenséggel szembeni védelem céljára kijelölt hatóság: Kereskedelmi, Haditechnikai, Exportellenőrzési és nemesfémhitelesítési Főosztály Idegenforgalmi és Közraktározás-felügyeleti Osztály 1124 Budapest, Németvölgyi út 37-39.\n' +
        '\n' +
        '2. A Jet Travel tájékoztatja az Utast, hogy a programjában közölt képek kifejezetten tájékoztató jellegűek és nem vállal felelősséget a képen rögzítettek változatlanságáért.\n' +
        '\n' +
        '3. Amennyiben az Utazási Szerződés bármely rendelkezése érvénytelen vagy végrehajthatatlan vagy azzá válna, úgy az Utazási Szerződés egyéb rendelkezései nem dőlnek meg, kivéve, ha a Felek az Utazási Szerződést az érvénytelen vagy végrehajthatatlan rendelkezés nélkül nem kötötték volna meg.\n' +
        '\n' +
        '4. Az Utazási Szerződésből származó vitás kérdéseket a Felek békés úton, peren kívül kötelesek rendezni. Amennyiben a vita ezúton történő rendezése sikertelenül zárul, úgy a Felek az általános szabályok szerint illetékes és hatáskörrel rendelkező bírósághoz fordulnak.\n' +
        '\n' +
        '5. A jelen Általános Utazási Feltételek mellékletét képezi továbbá a Jet Travel Panaszkezelési Szabályzata, amely tartalmazza az Utazási Szerződés esetleges hibás teljesítése esetére az Utas által igénybe vehető panaszkezelési és vitarendezési eljárásokról szóló tájékoztatást.\n' +
        '\n' +
        '6. Jelen Általános Utazási Feltételek a hatálybalépésüket követően megkötött Utazási Szerződésekre alkalmazandók.\n' +
        '\n' +
        '7. A Jet Travel az utazási csomag megkezdése előtt megfelelő időben, az utazási csomag igénybevétele előtt az Utas rendelkezésére bocsátja az utazási csomag igénybevételéhez szükséges nyugtákat, utalványokat, utazó nevére szóló biztosítási kötvényt, menetjegyeket, valamint az indulás tervezett időpontjára, az utasfelvétel időpontjára, a közbenső megállók, a csatlakozások, és az érkezés tervezett időpontjára vonatkozó tájékoztatást.\n' +
        '\n' +
        'Hatályba lép: 2018. július 1. napján\n' +
        '\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>Általános utazási feltételek</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default TravelPolicy