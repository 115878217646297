import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    ListItemText, Select,
    Stack,
    useTheme
} from "@mui/material";
import whiteLogo from "../assets/shared/jet-travel-logo-white.svg"
import {Link, useHistory} from "react-router-dom";
import Container from "@mui/material/Container";
import packageJson from '../../package.json'
import menuItems from "../utils/menuItems";
import {useContext, useEffect, useState} from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MenuItem from "@mui/material/MenuItem";
import TikTokIcon from "../TikTokIcon";
import {facebook, instagram, tiktok} from "../utils/socials";
import ConfigContext from "../context/ConfigContext";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;

function DrawerAppbar(props) {
    const {w} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [scrollHeight, setScrollHeight] = useState(0)
    const theme = useTheme()
    const history = useHistory()
    const {t} = useTranslation()
    const {setAppLanguage, language} = useContext(ConfigContext)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLanguageChange = (event) => {
        setAppLanguage(event.target.value);
    };

    const drawer = (
        <div style={{background: theme.palette.primary[500], height: '100%'}}>
            <Toolbar style={{background: theme.palette.primary[600]}}>
                <img src={whiteLogo} alt="Jettravel logo" width='100px' style={{padding: '5px'}}/>
            </Toolbar>
            <Divider/>
            <List sx={{padding: '32px 16px'}}>
                {menuItems.map((item, index) => {
                    const isExternal = item.url.startsWith('http');
                    const Component = isExternal ? 'a' : Link;
                    const componentProps = isExternal ? { href: item.url, target: '_blank', rel: 'noopener noreferrer' } : { to: item.url };

                    return (
                        <ListItem key={item.name} className="menu-item" disablePadding sx={{paddingBottom: '3px', color: 'white', textTransform: 'capitalize'}}
                                  component={Component}
                                  {...componentProps}>
                            <ListItemButton className="menu-button" sx={{"&:hover": {background: theme.palette.primary[400]}}}>
                                {/*<ListItemIcon className="menu-button__icon">*/}
                                {/*    {item.icon}*/}
                                {/*</ListItemIcon>*/}
                                <Typography variant="button" sx={{textTransform: 'capitalize'}} className="menu-button__text">{t(item.name)}</Typography>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <Typography variant='caption' sx={{
                color: '#fff',
                position: 'absolute',
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }}>v. {packageJson.version}</Typography>
        </div>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    const scrollWithOffset = (el) => {
        setTimeout(() => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -50;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 500)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", scrollListener)
    }, [])

    const scrollListener = (event) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setScrollHeight(winScroll)
    }


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar style={(props?.fade && scrollHeight < 400) ? {backgroundColor: 'transparent', transition: 'background-color 0.5s ease', color: 'white'} : {backgroundColor: theme.palette.primary[400], transition: 'background-color 0.5s ease', color: 'white'}} position="fixed">
                <Container maxWidth="xl">

                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 0, display: 'flex'}}>
                            <img src={whiteLogo} alt="Jettravel logo" width='100px' style={{padding: '5px', cursor: "pointer"}} onClick={() => history.push('/')}/>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginLeft: 'auto', zIndex: '11'}}>
                            <Stack direction='row' sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <IconButton
                                    color="inherit"
                                    component="a"
                                    href={facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    component="a"
                                    href={tiktok}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <TikTokIcon width='22px' height='22px'/>
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    component="a"
                                    href={instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <InstagramIcon />
                                </IconButton>

                            </Stack>
                            <FormControl variant="standard" sx={{paddingRight: 0, width: 'fit-content'}}>
                                <Select
                                    id="language-selector"
                                    value={language}
                                    onChange={handleLanguageChange}
                                    IconComponent={() => null} // This removes the down arrow
                                    sx={{ display: 'block', color: '#fff', background: 'none' }}
                                >
                                    <MenuItem value="en">
                                        <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                            <ListItemText primary="EN" />
                                        </Stack>
                                    </MenuItem>
                                    <MenuItem value="hu">
                                        <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                            <ListItemText primary="HU" />
                                        </Stack>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{color: '#fff'}}>
                                <IconButton
                                    size="large"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    {!mobileOpen ? <MenuIcon/> : <CloseIcon/>}
                                </IconButton>
                            </div>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: 0}, flexShrink: {sm: 0}}}
                aria-label="menu"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    anchor='right'
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: 'block',
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, width: '100%'}}
            >

                {props.children}

            </Box>
        </Box>
    );
}

export default DrawerAppbar;