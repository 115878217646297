import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";

const ASZF = () => {
    const text = '- KÖZVETÍTETT EGYEDI UTAZÁSI SZOLGÁLTATÁS ESETÉN -\n' +
        '\n' +
        '1. A SZERZŐDÉS LÉTREJÖTTE\n' +
        '\n' +
        'A jelen Általános Szerződési Feltételek megfelelően irányadók, amennyiben az Utas a Szolgáltatást a „Jet Travel” Kft. (1113 Budapest, Bocskai út 77-79. B. ép. 3. em., cégjegyzékszám: Cg 01-09-063771, adószám: 10274837-2-43, a továbbiakban „Jet Travel”) e-mail címére küldött elektronikus levél útján, telefonos vagy személyes egyeztetés útján foglalja le, illetve rendeli meg.\n' +
        '\n' +
        'A jelen Általános Szerződési Feltételek alkalmazásában „Utasnak” minősül (i) az a természetes személy, jogi személy vagy jogi személyiséggel nem rendelkező szervezet, aki a Szolgáltatásra vonatkozó foglalást, megrendelést leadja a Jet Travel felé, és teljesíti az ezzel kapcsolatos fizetési kötelezettségeket, továbbá – szövegkörnyezettől függően - (ii) az a természetes személy, aki a Szolgáltatást és az ahhoz kapcsolódó egyéb szolgáltatásokat ténylegesen igénybe veszi.\n' +
        '\n' +
        'A jelen Általános Szerződési Feltételek alkalmazásában „Szolgáltatásnak” minősül minden olyan utazási vagy utazással összefüggő szolgáltatás, amelyet az Utas egyedileg, különálló szolgáltatásként (nem utazási csomag vagy szolgáltatásegyüttes részeként) rendel meg a Jet Travel-en keresztül az adott szolgáltatást nyújtó személytől (a továbbiakban: „Szolgáltató”). A Szolgáltatás különösen, de nem kizárólag légi vagy egyéb személyfuvarozást, szálláshelybiztosítást (annak valamennyi kapcsolódó szolgáltatásával, pl. étkezéssel együtt), vízumügyintézést vagy gépjárműkölcsönzést jelent.\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy a Szolgáltatás fentiek szerinti megrendelése, lefoglalása során a Jet Travel csupán közvetítőként jár el, így a tényleges szolgáltatási szerződés minden esetben az Utas és az adott Szolgáltató (pl. légitársaság, szálloda, autókölcsönző stb.) között jön létre. Ennek megfelelően az Utas és a Jet Travel között az adott Szolgáltatás megrendelésével a jelen Általános Szerződési Feltételek alapján közvetítői szerződés jön létre.\n' +
        '\n' +
        'A szerződés az Utas és a Szolgáltató között akkor jön létre, amikor a Szolgáltatás megrendelésre kerül. A létrejött szerződésre minden esetben az adott Szolgáltató üzletszabályzatának feltételei és szabályai, illetve általános szerződési feltételei az irányadók, és közvetítői feladatai ellátása során a Jet Travel is ezen szabályoknak megfelelően jár el (ideértve különösen a foglalások módosítását és lemondását, poggyászkár bekövetkezését, vagy bármely, az Utas által okozott kárt).\n' +
        '\n' +
        '\n' +
        '\n' +
        '2. UTAZÁSI ADATOK MEGADÁSA; ADATVÉDELEM\n' +
        '\n' +
        'Az Utas adatait az érvényes és az utazáshoz használt dokumentumban (útlevél vagy személyi igazolvány) szereplővel megegyező módon köteles megadni a Jet Travel részére. A Szolgáltató megtagadhatja az adott Szolgáltatás igénybevételét, amennyiben az azonosításra használt dokumentumban és a repülőjegyen vagy voucheren eltérő adatok szerepelnek.Az Utas adatait az érvényes és az utazáshoz használt dokumentumban (útlevél vagy személyi igazolvány) szereplővel megegyező módon köteles megadni a Jet Travel részére. A Szolgáltató megtagadhatja az adott Szolgáltatás igénybevételét, amennyiben az azonosításra használt dokumentumban és a repülőjegyen vagy voucheren eltérő adatok szerepelnek.\n' +
        '\n' +
        'Ha a foglalást követően küldött visszaigazolásban az Utas az ellenőrzés során pontatlanságot talál, kötelessége haladéktalanul értesíteni erről a Jet Travel-t. Ennek elmulasztása esetén a helytelen adatok megadásából származó károkért a Jet Travel további felelősséget nem vállal.\n' +
        '\n' +
        'A Jet Travel egyebekben az Utas adatainak kezelése során a Jet Travel adatvédelmi tájékoztatójában (https://www.jettravel.hu/adatkezelesi-szabalyzat) foglaltaknak megfelelően jár el, amelyet az Utas a jelen Általános Szerződési Feltételek elfogadásával egyidejűleg megismer és elfogad.\n' +
        '\n' +
        '\n' +
        '\n' +
        '3. UTAZÁSI ÉS EGYÉB DOKUMENTUMOK\n' +
        '\n' +
        'A Jet Travel a foglalás során információt nyújt az utazáshoz szükséges dokumentumokról, vízumokról vagy egyéb utazási feltételekről, azonban ezen dokumentumok rendelkezésre állása az Utas felelőssége.\n' +
        '\n' +
        'A Jet Travel nem ellenőrzi és nem vállal felelősséget azért, ha az utazás a szükséges dokumentumok, vízum vagy egyéb utazási feltétel hiányában meghiúsul.\n' +
        '\n' +
        'A legszigorúbb nemzetközi előírások akár a visszaérkezés dátumát követő további 6 hónapos érvényességet is előírhatnak az okmányok (útlevél, személyi igazolvány) esetében. Az Utas köteles minden esetben ellenőrizni az átszállási város országához és a célországhoz tartozó vízumszabályokat az adott ország(ok) külképviseletén.\n' +
        '\n' +
        'További információk a https://konzuliszolgalat.kormany.hu/ weboldalon érhetők el.\n' +
        '\n' +
        'Vízumügyintézés esetén:\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy amennyiben a vízumigénylésre leadott dokumentumok hiányosak vagy nem megfelelően lettek kitöltve, a Jet Travel 5.000.- Ft összegű sürgősségi díjat számíthat fel a vízumkérelem időben történő benyújtása érdekében.\n' +
        '\n' +
        '\n' +
        '\n' +
        '4. A MEGRENDELÉS MENETE\n' +
        '\n' +
        'Az Utasnak a Szolgáltatást minden esetben a megrendelés visszaigazolásában feltüntetett határidő letelte előtt kell megrendelnie, mert a Szolgáltató automatikusan törölheti az időben meg nem rendelt foglalást.\n' +
        '\n' +
        'Repülőjegy-vásárlás esetén:\n' +
        '\n' +
        'A légitársaság a fizetési határidőt a foglalást követően is bármikor megváltoztathatja. Ilyen esetben a Jet Travel emailben értesíti az Utast a megváltozott határidőről.\n' +
        '\n' +
        'A légitársaság a foglalást követően, a jegykiállításig bármikor visszavonhatja díjtételeit. Így – ha ritkán is – de előfordulhat, hogy a fizetési határidő lejárta előtt sem lehet biztosítani a visszaigazolásban megadott árat.\n' +
        '\n' +
        'Árgaranciát a légitársaságok csak a kiállított repülőjegyekre adnak, ezért javasolt a repülőjegyek mielőbbi megrendelése.\n' +
        '\n' +
        'A repülőjegyek megrendelését követően a Jet Travel elektronikus úton küldi meg a repülőjegyeket az Utas részére.\n' +
        '\n' +
        'Az Utas felelőssége, hogy az általa megadott email fiók az elektronikus repülőjegy fogadására alkalmas legyen. A Jet Travel az email fiók nem megfelelő működéséből eredő károkért nem vállal felelősséget.\n' +
        '\n' +
        'Szálláshely biztosítása esetén:\n' +
        '\n' +
        'A szálláshely-szolgáltatás megrendelését követően a Jet Travel az Utas részére vouchert biztosít.\n' +
        '\n' +
        'A lefoglalt és a fizetési határidőn belül kiegyenlített szálláshely-megrendelések voucherének kiállítása, beszerzése a Jet Travel kötelessége. Továbbá Jet Travelnek kötelessége arról is gondoskodni, hogy a vouchert az Utas időben kézhez is kapja, akár kinyomtatott, akár elektronikus formában.\n' +
        '\n' +
        'Az Utas felelőssége, hogy az általa megadott e-mail fiók az elektronikus voucher fogadására alkalmas legyen. A Jet Travel az email fiók nem megfelelő működéséből eredő károkért nem vállal felelősséget.\n' +
        '\n' +
        'Vízumügyintézés esetén:\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy ha a vízumkérelem postai úton történő benyújtáshoz már nincs elegendő idő, úgy személyes átadás útján kerülhetnek a dokumentumok a vízumügyintéző birtokába. Ilyen esetekben a Jet Travel egyedi felárat számíthat fel (pl. vidékre történő utazás esetén).\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy a postán feladott dokumentumok késedelmes megérkezése, illetve elvesztése esetén a Jet Travel felelősséget nem vállal.\n' +
        '\n' +
        '\n' +
        '\n' +
        '5. A REPÜLŐJEGYEK, ILLETVE VOUCHEREK FELHASZNÁLÁSA, MÓDOSÍTÁSA, VISSZATÉRÍTÉSE\n' +
        '\n' +
        'Repülőjegy-vásárlás esetén:\n' +
        '\n' +
        'A légitársaságok a jegy módosításának, törlésének és árváltoztatásának a jogát fenntartják a jegy megvásárlásáig, így az ebből származó kellemetlenségekért a Jet Travel nem vállal felelősséget.\n' +
        '\n' +
        'A repülő-viteldíjak csak a foglalás pillanatában rendelkezésre álló helyek alapján határozhatóak meg, az adott járat(ok)ra.\n' +
        '\n' +
        'A viteldíj az indulási repülőtérről az érkezési repülőtérre történő légi szállítás díját tartalmazza. Magában foglalja a repülőjegy árát, az állami és repülőtéri adókat és illetékeket. A viteldíjat nem befolyásolja ezen összegek változása a jegyvásárlás napja és az utazás megkezdésének napja között.\n' +
        '\n' +
        'Ha az Utasnak egyazon útvonalra és/vagy időpontra több foglalása is van, minél hamarabb köteles töröltetni azt a foglalást, amelyikre nem lesz szüksége, ugyanis a légitársaság a dupla foglalásokat előzetes figyelmeztetés nélkül törli. Az ebből eredő károkért a Jet Travel nem vállal felelősséget.\n' +
        '\n' +
        'Amennyiben az Utas az ajánlatot nem fogadja el és nem rendeli meg a kért repülőjegyeket, majd ezt követően ugyanarra a névre, dátumra és járatra újabb megrendelést ad le, úgy ilyen esetben a többszöri foglalás készítését egyes légitársaságok pótdíjjal sújthatják. Az ebből eredő károkért a Jet Travel nem vállal felelősséget, és az ilyen többletköltségeket tovább számlázza az Utas felé.\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy a kiállított repülőjegyek egyes kuponjai, szakaszai csak a kiállított sorrendben, folytatólagosan használhatók fel. Amennyiben tehát az Utas oda-vissza jeggyel vagy több utazási szakasszal rendelkezik, és a jegy valamely szakaszát nem használja fel („no-show”), úgy a légitársaság automatikusan törli a jegyhez tartozó foglalást, és a további szakaszoknál megtagadja az utas szállítását. A Jet Travel ilyen esetekben nem tartozik felelősséggel az Utas felé.\n' +
        '\n' +
        'Ha az Utasnak egyazon útvonalra és/vagy időpontra több foglalása is van, minél hamarabb köteles töröltetni azt a foglalást, amelyikre nem lesz szüksége, ugyanis a légitársaság a dupla foglalásokat előzetes figyelmeztetés nélkül törli. Az ebből eredő károkért a Jet Travel nem vállal felelősséget.\n' +
        '\n' +
        'A megnövekedett légiforgalom és a szigorodó biztonsági előírások miatt a repülőjegyen szereplő indulási idő előtt legalább 3 órával meg kell jelenni a repülőtéren. Egyes repülőterek esetén ennél szigorúbb szabályok is előfordulhatnak. A járatra való bejelentkezés legkésőbbi megengedett időpontja légitársaságonként, repülőterenként és útvonalanként változhat, ezért az Utas az utazást megelőzően a Jet Travel-nél vagy a légitársaság honlapján érdeklődhet. A bejelentkezési határidő a repülőjegyen is megtalálható. Ezen szabályok betartása az Utas kizárólagos felelőssége.\n' +
        '\n' +
        'A már kiállított repülőjegyek kizárólag a légitársaságok előírásainak megfelelően módosíthatók vagy válthatók vissza, általában az egyes légitársaságok díjszabása által meghatározott pótdíj megfizetése ellenében. A kiállított repülőjegyek esetében az útvonal és az Utas neve – egyes légitársaságok kivételével – általában nem módosítható.\n' +
        '\n' +
        'Az Utas változtatási, illetve lemondási szándékát minden esetben írásban jelezheti a Jet Travel felé.\n' +
        '\n' +
        'A módosítás, visszatérítés lehetőségéről és az esetlegesen felmerülő költségekről a Jet Travel írásban tájékoztatja az Utast. A tájékoztatás elfogadását követően a Jet Travel elvégzi a kért változtatást, illetve lemondást. Az előre meghatározott szolgáltatási díjakat az Utas a módosításra, illetve visszatérítésre vonatkozó megrendelés esetleges lemondása esetén is köteles Jet Travel részére megfizetni.\n' +
        '\n' +
        'Amennyiben előre látható, hogy az Utas nem fogja elérni a lefoglalt járatot, úgy lehetőség szerint köteles legalább 3 órával indulás előtt jelezni a Jet Travel felé a módosítási kérelmet. Az időben érkező módosítási kérelmek általában még árdifferencia és módosítási díj ráfizetésével elintézhetők. A 3 órán belüli, illetve a járat indulását követő kérelmek esetében a módosítás már csak új jegy vásárlásával lehetséges. Kivételes esetekben a légitársasági szabályok ettől eltérhetnek és „no show” esetén is engedélyezik a ráfizetéses módosítást. A feltételekről a foglalási rendszer nyújt tájékoztatást.\n' +
        '\n' +
        'Szálláshely biztosítása esetén:\n' +
        '\n' +
        'A szálláshelyek a szabad szobák számát, illetve az ajánlat módosításának, törlésének és árváltoztatásának a jogát fenntartják a szobák és ellátás véglegesítéséig, így az ebből származó kellemetlenségekért a Jet Travel nem vállal felelősséget.\n' +
        '\n' +
        'A szobaár a szobahasználatot, illetve az ennek szerves részét képező ellátást (önellátás, reggeli, félpanzió, teljes panzió vagy all inclusive ellátás) és a mindenkori jogi szabályozásnak megfelelően irányadó adókat is tartalmazza, azonban nem tartalmazza az idegenforgalmi díjat, amely minden esetben a helyszínen fizetendő.\n' +
        '\n' +
        'Ha az Utasnak egyazon szállodában és időpontra több foglalása is van, minél hamarabb köteles töröltetni azt a foglalást, amelyikre nem lesz szüksége, ugyanis a szálláshelyek a dupla foglalásokat előzetes figyelmeztetés nélkül törölhetik. Az ebből adódó károkért a Jet Travel nem vállal felelősséget és az ebből eredő esetleges többletköltségeket továbbszámlázza az Utas felé.\n' +
        '\n' +
        'A szálláshelyre a bejelentkezés és a kijelentkezés előre megadott időpontokban történik, melyről a Jet Travel előre értesíti az Utast.\n' +
        '\n' +
        'A Jet Travel az általa közvetített szálláshely-szolgáltatásról vouchert, illetve ahhoz kapcsolódóan számlát állít ki az Utas részére. Az Utas a szálláshely-szolgáltatást a kapott voucher birtokában, annak felmutatásával veheti igénybe az adott szálláshelyen.\n' +
        '\n' +
        'A Jet Travel által kiállított voucher feljogosítja az Utast, hogy a korábban előre kifizetett szolgáltatásokat a helyszínen igénybe vehesse. Minden egyéb, a helyszínen igénybe vett fizetős szolgáltatás (késői kijelentkezés, telefonszámla, minibár-fogyasztás, mosodai szolgáltatások stb.) helyben fizetendő közvetlenül a szálláshelyen.\n' +
        '\n' +
        'No show: Az Utas tudomásul veszi, hogy amennyiben a szállás voucheren megjelölt érkezési napon nem jelenik meg az adott szálláshelyen, és erről nem tájékoztatja előre a Jet Travelt vagy a szállásszolgáltatót, úgy a szálláshely automatikusan törölheti a foglalást, melyet így a további éjszakákra sem használhat fel. A Jet Travel ilyen esetekben nem tartozik felelősséggel az Utas felé.\n' +
        '\n' +
        'A foglalások, illetve a már kiállított voucherek kizárólag a szálláshelyek előírásainak megfelelően módosíthatók vagy válthatók vissza, általában ráfizetés ellenében. Az Utas változtatási, illetve lemondási szándékát minden esetben köteles írásban jelezni a Jet Travel felé.\n' +
        '\n' +
        'A módosítás, visszatérítés lehetőségéről és az esetlegesen felmerülő költségekről a Jet Travel köteles írásban tájékoztatni az Utast, majd annak kérése szerint eljárni a szállásadónál.\n' +
        '\n' +
        'Amennyiben előre látható, hogy Utas nem fogja tudni igénybe venni az előre lefoglalt szálláshelyet, úgy ezt lehetőség szerint jelezze a Jet Travel felé a veszteségek minimalizálása érdekében.\n' +
        '\n' +
        '\n' +
        '\n' +
        '6. SZÁMLÁZÁS ÉS FIZETÉSI FELTÉTELEK\n' +
        '\n' +
        'A Jet Travel az utazási szolgáltatások jelen Általános Szerződési Feltételek szerinti közvetítéséért közvetítői díjat számít fel, amelyről számlát állít ki az Utas részére. Az Utas köteles a számla ellenértékét az azon feltüntetett fizetési határidőn belül a Jet Travel részére megfizetni.\n' +
        '\n' +
        'A számlát a Jet Travel a kért számlázási névre és címre állítja ki, majd emailen, illetve – külön kérés esetén – postai úton küldi meg az Utas részére.\n' +
        '\n' +
        'A Jet Travel által kiállított számviteli bizonylatok nem értelmezhetők akként, hogy a szálláshely - szolgáltatás az Utas és a Jet Travel között jönne létre.\n' +
        '\n' +
        'Számlamódosításra a számla kiállítást követően, 5.000.- Ft-os díj ellenében van lehetőség, a számla kiállítását követő 15 napon belül.\n' +
        '\n' +
        'A repülőjegyek és szállásfoglalások módosítása, illetve foglalások díjának visszatérítése külön szolgáltatásnak minősül, amelyért a légitársasági és szálláshelyi díjakon felül a Jet Travel módosítási, illetve lemondási díjat számít fel az Utas felé, amelynek mértékéről a Jet Travel mindig a megrendelés előtt ad pontos tájékoztatást Utas részére.\n' +
        '\n' +
        '\n' +
        '\n' +
        '7. FELELŐSSÉG\n' +
        '\n' +
        'A Jet Travel önhibáján kívül bekövetkezett, rendkívüli, elháríthatatlan e semények (vis maior: pl. természeti katasztrófák, háború, blokád, export- és importtilalom, érdekszférán kívüli sztrájk, járvány) esetén a teljesítés elmaradása miatt az Utas nem jogosult a szerződésszegés esetén alkalmazható jogkövetkezmények érvényesítésére.\n' +
        '\n' +
        'Az Utas tudomásul veszi, hogy a Jet Travel a jelen szerződés teljesítése során csupán közvetítőként jár el, ennek megfelelően mindennemű felelősséget kizár a Szolgáltató hibás teljesítése esetére. Amennyiben az Utasnak bármely kifogása merülne fel a Szolgáltatás teljesítésével kapcsolatban, ezt írásban jelezheti a Jet Travel felé, aki lehetőség szerint minden észszerű segítséget megad az Utasnak a problémás ügy rendezéséhez.\n' +
        '\n' +
        'A Felek megállapodnak, hogy a Jet Travel felelősségét a kifejezetten és közvetlenül a Jet Travel által ellátott közvetítői tevékenységgel kapcsolatos hibás teljesítés eseteire korlátozzák (ideértve különösen az egyes megrendelések és foglalások nem megfelelő vagy nem kellő időben történő leadását, továbbítását, kezelését), azzal, hogy a Jet Travel az Utas oldalán felmerült elmaradt haszonért, valamint közvetett és következményi károkért nem tartozik felelősséggel. A Jet Travel mentesül a fentiek szerinti felelősség alól, ha a Jet Travel kifejezetten az Utas útmutatása vagy az Utas által megadott adatok, illetve tájékoztatás alapján járt el, továbbá, ha a Jet Travel általi teljesítést (foglalást) az Utas vagy valamely, az Utas nevében eljáró személy kifejezetten vagy ráutaló magatartással elfogadta, anélkül, hogy bármilyen módosítási igényt jelzett volna a Jet Travel felé.\n' +
        '\n' +
        'A jelen Általános Szerződési Feltételek elválaszthatatlan mellékletét képezi továbbá a Jet Travel Panaszkezelési Szabályzata, amely megfelelően irányadó az Utas és a Jet Travel közötti jogviszonyra.\n' +
        '\n' +
        'Repülőjegy-vásárlás esetén:\n' +
        '\n' +
        'A Jet Travel tájékoztatja az Utast, hogy a légi fuvarozók felelősségét adott esetben nemzetközi egyezmények, így különösen a Montrealban, 1999. május 28-án kelt, a nemzetközi légi fuvarozásra vonatkozó egyes jogszabályok egységesítéséről szóló nemzetközi egyezmény (Magyarországon kihirdette: 2005. évi VII. törvény), valamint a nemzetközi légi fuvarozásra vonatkozó 1929. évi varsói nemzetközi egyezmény (Magyarországon kihirdette: 1936. évi XXVIII. törvény) is korlátozhatja.\n' +
        '\n' +
        'Autóbérlés esetén:\n' +
        '\n' +
        'Az Utas baleset esetén és bármilyen, a bérelt autót ért kár vagy a bérelt autó műszaki hibája esetén köteles a szerződésben megadott bérbeadó céget azonnal értesíteni a káresemény megtörténtekor, illetve a hiba észlelésekor.\n' +
        '\n' +
        'Amennyiben az autóbérlet időtartama alatt olyan kár vagy fizetési kötelezettség keletkezik a bérautóval kapcsolatban (pl. parkolási vagy egyéb bírság, rejtett kár stb.), amely az autó visszavételekor bármely okból nem jut a bérbeadó tudomására, úgy a bérbeadó az ilyen összegekre vonatkozó követelését utólag érvényesítheti a Jet Travel-lel szemben. Ebben az esetben a Jet Travel az összeget továbbterheli az Utas felé, aki köteles azt megfizetni a Jet Travel részére.\n' +
        '\n' +
        '\n' +
        'Hatályba lép: 2021. május 13. napján\n' +
        '\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>ÁSZF</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default ASZF