import Footer from "../../components/Footer";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import ImageCard from "../../components/ImageCard";
import HeroImage from "../../components/HeroImage";
import useScrollToTop from "../../hooks/useScrollToTop";
import TransparentAppBar from "../../components/TransparentAppbar";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import ImageStack from "../../components/ImageStack";
import DrawerAppbar from "../../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ConfigContext from "../../context/ConfigContext";
import {Helmet} from "react-helmet";
import mainImage from '../../assets/blog/Blog.JPG'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const Blog = () => {
    useScrollToTop()
    // const {getData: fetchBlog} = useCallDataApi('blog')
    const {getData: fetchBlogCategories} = useCallDataApi('blog-category')
    const [loading, setLoading] = useState(false)
    // const [blogs, setBlogs] = useState([])
    const [blogCategories, setBlogCategoris] = useState([])
    const {t} = useTranslation()
    const {language} = useContext(ConfigContext)
    const history = useHistory()
    document.title = `Jet Travel | ${t('blog.headline')}`

    useEffect(() => {
        setLoading(true)
        fetchBlogCategories().then(c => {
            setBlogCategoris(c)
            // fetchBlog().then(b => setBlogs(b))
        })
            .finally(() => setLoading(false))
    }, [])

    return <DrawerAppbar fade>
        {/*<Helmet>*/}
        {/*    <meta name="description" content="This is the home page of my website."/>*/}
        {/*    <meta property="og:title" content="Home Page - My Website"/>*/}
        {/*    <meta property="og:description" content="This is the home page of my website."/>*/}
        {/*    <meta property="og:url" content="https://www.mywebsite.com/"/>*/}
        {/*    <meta property="og:type" content="website"/>*/}
        {/*</Helmet>*/}
        <HeroImage title={t('blog.headline')} underlineColor='#ff0000' image={mainImage}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 2, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('blog.categories')}</Typography>
            </Container>

            <ImageStack images={blogCategories?.map(c => ({
                image: c?.image,
                title: c?.translations?.[language]?.name,
                link: `blog-category/${c?.id}`,
                description: c?.translations?.[language]?.description
            }))}/>

        </div>
        <Footer/>

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Blog