import {Backdrop} from "@mui/material";
import PulsatingLogo from "./LoadingLogo";


const Loading = ({isLoading}) => {

    return <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(5px)"}}
        open={isLoading}
    >
        <PulsatingLogo/>
    </Backdrop>
}

export default Loading