const menuItems = [
    // {
    //     name: 'menu.home',
    //     url: '/'
    // },

    {
        name: 'menu.aboutUs',
        url: '/about-us'
    },
    {
        name: 'menu.corporate',
        url: '/corporate'
    },
    {
        name: 'menu.incentive',
        url: '/incentive'
    },
    {
        name: 'menu.cruises',
        url: 'https://jetcruise.hu'
    },
    {
        name: 'menu.travelWithUs',
        url: 'https://utazas.jettravel.hu/'
    },
    {
        name: 'menu.sport',
        url: '/sport'
    },
    {
        name: 'menu.ski',
        url: '/ski-regions'
    },
    {
        name: 'menu.sustainability',
        url: '/sustainability'
    },
    // {
    //     name: 'menu.csr',
    //     url: '/csr'
    // },
    {
        name: 'menu.blog',
        url: '/blog'
    },
    {
        name: 'menu.contact',
        url: '/contact',
    },
]

export default menuItems;
