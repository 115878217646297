import ski from '../../assets/ski.jpeg'
import HeroImage from "../../components/HeroImage";
import {Box, Button, Container, Typography} from "@mui/material";
import ImageStack from "../../components/ImageStack";
import Footer from "../../components/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState} from "react";
import Loading from "../../components/Loading";
import DrawerAppbar from "../../components/DrawerAppbar";
import ConfigContext from "../../context/ConfigContext";
import {useTranslation} from "react-i18next";
import logo from '../../assets/shared/jet-adventure-logo-white-01.png'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const SkiRegions = () => {
    useScrollToTop()
    const {getData: fetchSkiRegions} = useCallDataApi('ski-region')
    const [skiRegions, setSkiRegions] = useState([])
    const [loading, setLoading] = useState(false)
    const language = 'hu'
    const {t} = useTranslation()
    const history = useHistory()
    document.title = `Jet Travel | ${t('ski.regions')}`

    useEffect(() => {
        setLoading(true)
        fetchSkiRegions().then(r => {
            setSkiRegions(r)
        }).finally(() => setLoading(false))
    }, [])


    return <DrawerAppbar fade>
        <HeroImage logo={logo} title={t('ski.title')} description={t('home.skiDescription')} underlineColor='#ff0000' image={ski}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 2, marginBottom: 10}}>
                <Typography textAlign='center' variant='h2' gutterBottom>{t('ski.regions')}</Typography>
                <Typography variant='body1' marginBottom={3}>{t('ski.description2')}</Typography>
            </Container>

            <ImageStack images={skiRegions?.sort((a, b) => b?.length - a?.length)?.map(c => ({
                image: c?.image,
                title: c?.translations?.[language]?.name,
                link: c?.link ? c?.link : `ski-region/${c?.id}`,
                description: c?.translations?.[language]?.description
            }))}/>


        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default SkiRegions