// src/components/ImageGallery.js
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/system';

const ImageItem = styled('img')({
    height: '150px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    marginRight: '10px',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

const DialogImage = styled('img')({
    width: '100%',
    height: 'auto',
});

const ImageGallery = ({ images }) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleClickOpen = (index) => {
        setSelectedIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedIndex(null);
    };

    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const handleNext = () => {
        setSelectedIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
            handlePrev();
        } else if (event.key === 'ArrowRight') {
            handleNext();
        }
    };

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, images]);

    return (
        <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: 2 }}>
            {images?.map((image, index) => (
                <ImageItem
                    key={index}
                    src={image.src}
                    alt={image.alt || `Image ${index + 1}`}
                    onClick={() => handleClickOpen(index)}
                />
            ))}

            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 4 }}>
                    <IconButton
                        onClick={handlePrev}
                        sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {selectedIndex !== null && (
                        <DialogImage src={images[selectedIndex].src} alt={images[selectedIndex].alt} />
                    )}
                    <IconButton
                        onClick={handleNext}
                        sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </DialogContent>
            </Dialog>
        </Box>
    );
};


export default ImageGallery;
