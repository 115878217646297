import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import {repairImageUrl} from "../utils/utils";

function useCallDataApi(model) {
    const {headers, formDataHeaders} = useAuthHeader();
    const {baseUrl} = useContext(ConfigContext);

    const url = baseUrl + '/' + model + '/';

    const baseApi = axios.create({
        baseURL: url,
        headers: headers
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })

    const handleError = (e) => {
        console.log(e)
        if (e?.code === 'ERR_NETWORK') alert('A szolgáltatás jelenleg nem elérhető!')
        if (e.response.status === 404) {
            console.log('Error 404')
            return
        }
        console.log(e.response?.data)
        try {
            JSON.stringify(e.response?.data)
        } catch (e) {
            console.log(e.response?.data)
        }
    }

    const createData = useCallback((data) => {
            return  baseApi.post("", JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [baseApi]
    );

    const postData = useCallback((slug, data) => {
            return  baseApi.post(slug, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [baseApi]
    );

    const getData = useCallback((id = null) => {
            return baseApi.get(id)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [baseApi]
    );

    const getDataPagination = useCallback((page = 1, limit = 50) => {
            return baseApi.get(`?page=${page}&limit=${limit}`)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [baseApi]
    );

    const updateData = useCallback((id, data) => {
            return baseApi.patch(`${id}`, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))

        },
        [baseApi]
    );

    const deleteData = useCallback((id) => {
            return  baseApi.delete(`${id}/`)
                .then(r => r)
                .catch(e => handleError(e))
        },
        [baseApi]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return  formDataAPI.post("", data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(`${id}/`, data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const getFile = useCallback(
        async (fileUrl, extension, name='data') => {
            const response = await fetch(repairImageUrl(fileUrl, baseUrl), {
                method: 'GET',
                headers,
            });
            const blob = await response?.blob();
            if (response.status === 200) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.${extension}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            return response;
        },
        [headers]
    );

    const openFile = (fileUrl) => {
        window.open(repairImageUrl(fileUrl, baseUrl), '_blank')
    }

    return {
        getFile,
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        formDataCreateData,
        formDataUpdateData,
        getDataPagination,
        openFile
    };
}

export default useCallDataApi;