import React, {useContext, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Divider from '@mui/material/Divider';
import ConfigContext from "../context/ConfigContext";  // Import Divider component

const EmployeeAvatar = ({ firstName, lastName, imageUrl, gifUrl, title, phone, email }) => {
    const [hovered, setHovered] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const {language} = useContext(ConfigContext)

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Avatar
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                src={hovered ? gifUrl : imageUrl}
                alt={firstName}
                sx={{ width: 150, height: 150 }}
            />
            <Typography textAlign="center" variant="subtitle1" sx={{ marginTop: 1 }}>
                {language === 'hu' ? `${lastName} ${firstName}` : `${firstName} ${lastName}`}
            </Typography>

            {/* Divider between name and title */}
            <Divider sx={{ width: '40%', marginTop: 1, marginBottom: 1, backgroundColor: '#e0e0e0' }} />

            <Typography textAlign="center" variant="body2">
                {title && title}
            </Typography>

            {/* Expand/Collapse Button */}
            <IconButton onClick={handleToggleExpand} sx={{ marginTop: 1 }}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>

            {/* Collapsible Details */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography textAlign="center" variant="body2" sx={{ marginTop: 1 }}>
                    {phone && phone}
                </Typography>
                <Typography textAlign="center" variant="body2" sx={{ marginTop: 1 }}>
                    {email && email}
                </Typography>
            </Collapse>
        </Box>
    );
};

export default EmployeeAvatar;
