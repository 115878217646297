import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {
    const getLanguage = () => {
        const availableLanguages = ['en', 'hu']

        const localLanguage = localStorage.getItem('lang');
        const navigatorLanguage = navigator.language;

        if (availableLanguages.includes(localLanguage)) return localLanguage
        if (availableLanguages.includes(navigatorLanguage)) return navigatorLanguage
        return 'en';
    }

    const [appLanguage, setAppLanguage] = useState(getLanguage())
    const {i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(appLanguage)
        localStorage.setItem('lang', appLanguage)
    }, [appLanguage])

    const contextData = {
        baseUrl: process.env.REACT_APP_BASE_URL,
        language: appLanguage,
        setAppLanguage: setAppLanguage
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}