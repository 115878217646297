// src/components/CountUpGrid.jsx
import React from 'react';
import {Grid, Box, Typography, Stack} from '@mui/material';
import {useInView} from 'react-intersection-observer';
import CountUp from 'react-countup';

const CountUpBox = ({number}) => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // height: 100,
                // width: 100,
            }}
        >
            {inView ? (
                <Typography variant="h4" component="div">
                    <CountUp start={0} end={number} duration={2}/>
                </Typography>
            ) : (
                <Typography variant="h4" component="div">
                    0
                </Typography>
            )}
        </Box>
    );
};

const CountUpGrid = ({numbers}) => {
    return (
        <Grid container spacing={4} justifyContent="center">
            {numbers.map((number, index) => (
                <Grid item key={index}>
                    {number?.reversed ? <Stack spacing={1}>
                            <Stack direction='row' justifyContent='center' spacing={1} alignItems='center'>
                                {number?.unit && <Typography variant='h5'>{number?.unit}</Typography>}
                                <CountUpBox number={number?.number}/>
                            </Stack>
                            <Typography>{number?.description}</Typography>
                        </Stack>
                        :
                        <Stack spacing={1}>
                            <Stack direction='row' justifyContent='center' spacing={1} alignItems='center'>
                                <CountUpBox number={number?.number}/> {number?.unit &&
                                <Typography variant='h5'>{number?.unit}</Typography>}
                            </Stack>
                            <Typography>{number?.description}</Typography>
                        </Stack>}
                </Grid>
            ))}
        </Grid>
    );
};

export default CountUpGrid;
