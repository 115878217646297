import React from "react";
import Footer from "../components/Footer";
import {Container, Typography, Box, Button} from "@mui/material";
import useScrollToTop from "../hooks/useScrollToTop";
import TransparentAppBar from "../components/TransparentAppbar";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    useScrollToTop()
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('notFound.title')}`

    return <DrawerAppbar fade>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
            style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1589652717521-10c0d092dea9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGNhY3R1c3xlbnwwfHx8fDE2Njg4NzIxMzg&ixlib=rb-1.2.1&q=80&w=1080)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <Container>
                <Typography variant="h1" style={{fontSize: '10rem', fontWeight: 'bold', color: '#ffffff'}} gutterBottom>
                    404
                </Typography>
                <Typography variant="h2" style={{color: '#ffffff'}} gutterBottom>
                    {t('notFound.headline')}
                </Typography>
                <Typography variant="body1" style={{color: '#ffffff'}} gutterBottom>
                    {t('notFound.text')}
                </Typography>
                <Box mt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        href="/"
                    >
                        {t('general.back')}
                    </Button>
                </Box>
            </Container>
        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default NotFound;
