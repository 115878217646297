import React, { useState } from "react";
import { Dialog, DialogContent, IconButton, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageDialog({ open, onClose, imageUrl }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            // BackdropComponent={Backdrop}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
        >
            <DialogContent
                style={{
                    position: "relative",
                    padding: 0,
                    overflow: "hidden",
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        color: "white",
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <img
                    src={imageUrl}
                    alt="Dialog Content"
                    style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

