import HeroImage from "../../components/HeroImage";
import {Box, Button, Grid} from "@mui/material";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import useScrollToTop from "../../hooks/useScrollToTop";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState} from "react";
import ImageCard from "../../components/ImageCard";
import {useHistory, useParams} from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import {repairImageUrl} from "../../utils/utils";
import DrawerAppbar from "../../components/DrawerAppbar";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useTranslation} from "react-i18next";

const BlogCategory = () => {
    useScrollToTop()
    const params = useParams()
    const {postData: postBlog} = useCallDataApi('blog')
    const {getData: fetchBlogCategories} = useCallDataApi('blog-category')
    const [loading, setLoading] = useState(false)
    const [blogs, setBlogs] = useState([])
    const [blogCategory, setBlogCategory] = useState({})
    const {baseUrl, language} = useContext(ConfigContext)
    const history = useHistory()
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true)
        fetchBlogCategories(params?.id).then(c => {
            setBlogCategory(c)
            document.title = `Jet Travel | ${c?.translations?.[language]?.name}`
            postBlog('for_category/', {category: c?.id}).then(b => {
                setBlogs(b)
            })
        })
            .finally(() => setLoading(false))
    }, [])
    return <DrawerAppbar fade>
        <HeroImage title={blogCategory?.translations?.[language]?.name} description={blogCategory?.translations?.[language]?.description} underlineColor='#ff0000' image={blogCategory?.image}/>
        <div style={{ marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {blogs?.map((blog, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ImageCard image={repairImageUrl(blog.thumbnail, baseUrl)} description={blog?.translations?.[language]?.summary} title={blog?.translations?.[language]?.title} link={`/blog-category/${blogCategory?.id}/${blog?.id}`} />
                    </Grid>
                ))}
            </Grid>

        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default BlogCategory