import React, {useState} from 'react';
import {Box, Typography, Card, CardMedia, useMediaQuery, useTheme, Grid, Button} from '@mui/material';
import {styled} from '@mui/system';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {createImageUrl} from "../utils/utils";
import {useTranslation} from "react-i18next";

const Container = styled(Box)({
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
});

const ImageBox = styled(Box)(({isHovered}) => ({
    position: 'relative',
    transition: 'all 0.8s ease',
    width: isHovered ? '100%' : '100px',
    height: '85dvh',
    overflow: 'hidden',
    borderRight: '5px solid rgba(255, 255, 255, .6)',
    '&:hover': {
        zIndex: 10,
    },
}));

const Image = styled('img')(({isHovered}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: isHovered ? '100%' : 'auto',
    height: isHovered ? '100%' : '85dvh',
    objectFit: 'cover',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.8s ease',
    filter: isHovered ? 'brightness(1)' : 'brightness(0.5)',

}));

const Title = styled(Typography)(({isHovered, isMobile}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    transform: isHovered ? 'translate(-50%, -50%) rotate(0deg)' : 'translate(-50%, -50%) rotate(90deg)',
    color: '#fff',
    fontWeight: 'bold',
    transition: 'all 0.8s ease',
    zIndex: 1,
    whiteSpace: 'nowrap',
    padding: '5px',
    background: !isMobile && isHovered ? 'rgba(0,0,0, .8)' : '',
    boxShadow: !isMobile && isHovered ? '0 0 20px 20px rgba(0,0,0, .8)' : '',
}));

const Overlay = styled(Box)({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '10px',
    fontSize: '105%',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    opacity: 0,
    animation: 'fadeIn .6s ease-in .2s forwards', // Animation to fade in with a 1-second delay
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '-30px', // Positioned 30px above the bottom of the overlay
        left: 0,
        right: 0,
        height: '30px',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent)',
    },
    '@keyframes fadeIn': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
});

const ImageStack = ({images}) => {
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {t} = useTranslation()
    const history = useHistory()

    const isExternalLink = (url) => {
        return url?.startsWith('http://') || url?.startsWith('https://');
    };

    return (
        <Container sx={{flexDirection: isMobile ? 'column' : 'row'}}>
            {
                isMobile ? (
                    <Grid container spacing={4} justifyContent="center" alignItems="center" marginBottom={4}>
                        {images.map((card, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                {isExternalLink(card.link) ? (
                                    <a href={card.link} target="_blank" rel="noopener noreferrer"
                                       style={{textDecoration: 'none'}}>
                                        <Card sx={{
                                            background: `url(${typeof card?.image === 'string' ? card.image : createImageUrl(card.image)})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            minHeight: '500px',
                                            position: 'relative'
                                        }}>
                                            <Overlay>
                                                <Typography variant="h6">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {card.description}
                                                </Typography>
                                            </Overlay>
                                        </Card>
                                    </a>
                                ) : (card?.link ? <RouterLink to={card?.link} style={{textDecoration: 'none'}}>
                                        <Card sx={{
                                            background: `url(${typeof card?.image === 'string' ? card.image : createImageUrl(card.image)})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            minHeight: '500px',
                                            position: 'relative'
                                        }}>
                                            <Overlay>
                                                <Typography variant="h6">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {card.description}
                                                </Typography>
                                            </Overlay>
                                        </Card>
                                    </RouterLink> : <Card onClick={() => card?.onClick()} sx={{
                                        background: `url(${typeof card?.image === 'string' ? card.image : createImageUrl(card.image)})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        minHeight: '500px',
                                        position: 'relative'
                                    }}>
                                        <Overlay>
                                            <Typography variant="h6">
                                                {card.title}
                                            </Typography>
                                            <Typography variant="body2">
                                                {card.description}
                                            </Typography>
                                        </Overlay>
                                    </Card>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <>
                        {images.map((image, index) => (
                            <ImageBox
                                key={index}
                                onMouseEnter={() => setHoveredIndex(index)}
                                isHovered={hoveredIndex === index}
                            >
                                {isExternalLink(image.link) ? (
                                    <div style={{display: 'block', width: '100%', height: '100%'}}>
                                        <Image src={image.image} alt={image.title} isHovered={hoveredIndex === index}/>
                                        <Title variant="h6" isHovered={hoveredIndex === index}>
                                            {image.title}
                                            {hoveredIndex === index && <Button className='button-open' variant='standard'
                                                     onClick={() => window.open(image.link)}>{t('general.open')}</Button>}
                                        </Title>
                                        {hoveredIndex === index && (
                                            <Overlay>
                                                <Typography variant="body2" fontSize='105%'>
                                                    {image.description}
                                                </Typography>
                                            </Overlay>
                                        )}
                                    </div>
                                ) : (
                                    (image?.link ? <div style={{
                                        display: 'block',
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <Image src={image.image} alt={image.title} isHovered={hoveredIndex === index}/>
                                        <Title variant="h6" isHovered={hoveredIndex === index}>
                                            {image.title}
                                            {hoveredIndex === index && <Button className='button-open' variant='standard'
                                                     onClick={() => history.push(image.link)}>{t('general.open')}</Button>}
                                        </Title>
                                        {hoveredIndex === index && (
                                            <Overlay>
                                                <Typography variant="body2" fontSize='105%'>
                                                    {image.description}
                                                </Typography>
                                            </Overlay>
                                        )}
                                    </div> : <div style={{display: 'block', width: '100%', height: '100%'}}>
                                        <Image src={image.image} alt={image.title} isHovered={hoveredIndex === index}/>
                                        <Title variant="h6" isHovered={hoveredIndex === index}>
                                            {image.title}
                                            {image?.onClick && <Button className='button-open' variant='standard'
                                                                               onClick={() => image?.onClick()}>{t('general.open')}</Button>}
                                        </Title>
                                        {hoveredIndex === index && (
                                            <Overlay>
                                                <Typography variant="body2" fontSize='105%'>
                                                    {image.description}
                                                </Typography>
                                            </Overlay>
                                        )}
                                    </div>)
                                )}
                            </ImageBox>
                        ))}
                    </>
                )
            }
        </Container>
    );
};

export default ImageStack;
