import {FormHelperText, Select} from "@mui/material";


const CustomSelect = ({errors, setErrors, data, setData, name, validators, children, sideEffect, ...rest}) => {

    const handleChange = (event) => {
        const value = event.target.value;
        setData({...data, [name]: value});
        if (sideEffect) sideEffect(value)
    };

    const handleBlur = (event) => {
        const value = event.target.value;
        for (let validator of validators[name]) {
            const {valid, error} = validator(value)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    };

    return <>
        <Select
            name={name}
            value={data[name]}
            onChange={e => {
                handleBlur(e)
                handleChange(e)
            }}
            onBlur={handleBlur}
            error={Boolean(errors[name])}
            {...rest}
        >
            {children}
        </Select>
        {errors[name] && <FormHelperText sx={{color: '#F44335'}}>{errors[name]}</FormHelperText>}
    </>
}

export default CustomSelect