import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";

const GDPR = () => {
    const text = 'Jet Travel Kft. (registered seat: 1113 Budapest, Bocskai út 77-79., tax number: 10274837-2-43) makes\n' +
        'the following legally effective statement:\n' +
        '1. We represent and warrant that the personal data of third-party individuals (clients, customers) who\n' +
        'order our services in person at our place of business, over the phone or via email, are processed by the\n' +
        'Company as controller.\n' +
        '2. Furthermore, we represent and warrant that such data processing is performed in accordance with\n' +
        'our Privacy Notice and the currently effective laws, in particular Regulation (EU) 2016/679 of the\n' +
        'European Parliament and of the Council on the protection of natural persons with regard to the\n' +
        'processing of personal data and on the free movement of such data (GDPR) and Act CVIII of 2001 on\n' +
        'Electronic Commerce and on Information Society Services governing the activities of our Company.\n' +
        '3. We declare that in the course of controlling or eventually processing any data, we act in full\n' +
        'accordance with the legal provisions stipulated in Section 2 herein.\n' +
        '4. We declare that prior to the commencement of data processing Jet Travel Kft. uses its best efforts\n' +
        'to ensure the right of information to every data subject so that they have clear and detailed\n' +
        'information on the processing of their personal data, and also their rights and remedies related to and\n' +
        'associated with data processing.\n' +
        '5. Throughout the course of processing data Jet Travel Kft. shall endeavor to provide personal data\n' +
        'priority protection, while also ensuring the right to informational self-determination of data subjects.\n' +
        'To this end collected personal data receive appropriate protection in accordance with the legal\n' +
        'provisions listed in Section 2 herein. The protection of personal data also includes their physical\n' +
        'protection, which protects them from destruction, unauthorized changes, access, use, or transfer.\n' +
        'Budapest, 14 October 2019\n' +
        'Pál Zoltán Gál\n' +
        'Managing Director'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>GDPR Compliance Statement</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default GDPR