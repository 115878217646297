import DrawerAppbar from "../../components/DrawerAppbar";
import {Box, Container, Typography} from "@mui/material";
import Footer from "../../components/Footer";
import eu from "../../assets/aboutUs/eu.png"

const EU = () => {
    const text = 'GINOP Plusz-3.2.1-21 – A munkavállalók és vállalatok alkalmazkodóképességének és termelékenységének javítása a munkaerő fejlesztésén keresztül című kiemelt projekt\n' +
        ' \n' +
        ' A kedvezményezett neve: Jet Travel Kft.\n' +
        'A Jet Travel elkötelezett munkavállalói továbbképzése iránt. Hiszünk abban, hogy minőségi szolgáltatást csak naprakész információk birtokában lehet nyújtani. Munkavállalóink így 2022. január 10-i kezdettel összesen négy képzésben vettek részt, amelyek segítették nyelvi, jogi- és adóügyi, kommunikációs készségeik fejlesztését, kiegészítve a jövő zöld, szociális és digitális igényeire kialakított készségfejlesztéssel, ami a modern turisztikai szolgáltatásnyújtás alapja.\n' +
        'A képzések elnevezése és célja:\n' +
        '1.\tAngol nyelvi képzés: A képzési programban megjelölt idegen nyelvi kompetenciák elsajátítása.\n' +
        '2.\tJogi- és adóügyi ismeretek: A résztvevők felkészítése és kompetencia fejlesztése a vállalatnál használatos MRC rendszerhasználati, jogszabályi, ügyviteli, adóügyi, ISO, GDPR ismereteik kapcsán.\n' +
        '3.\tKomplett készségfejlesztés: Készség-megújítás és fejlesztés zöld, digitális és szociális kompetenciák megismertetésével.\n' +
        '4.\tKommunikációs tréning: Cél, hogy a kommunikáció lényegének, formáinak, szabályszerűségeinek megismerése és gyakorlása révén a résztvevők szóbeli és nem szóbeli kifejezése sikeresebbé váljon, erősödjön a szóbeli és nem szóbeli kommunikációjuk közti összhang.\n' +
        ' A szerződött támogatás összege:\n' +
        'Képzési költségtámogatás formájában 2 400 300,- Ft,\n' +
        'Kieső munkaidőre járó bértámogatás: legfeljebb 1 868 118,- Ft.\n' +
        'A képzések részletes bemutatása:\n' +
        'Angol nyelvi képzés (2022. 01. 10. – 2022. 02. 17.): A hosszabb beszédek és előadások megértésének gyakorlása, a bonyolultabb érvelések követése. Az aktuális eseményekről, szakterületéről, ismert üzleti témákról szóló műsorok megértése. Az anyanyelvi beszélővel természetes, könnyed és közvetlen kapcsolatteremtésre készít fel. Aktívan részt tud venni az ismert témákról folyó társalgásban, úgy, hogy közben érvelve kifejti a véleményét. Világosan és kellő részletességgel való önkifejezés számos szakterülethez tartozó vagy üzleti témában. Üzleti levelezés során rá tud világítani arra, hogy milyen jelentőséget tulajdonít az eseményeknek, élményeknek.\n' +
        'Szociolingvisztikai kompetencia: Magabiztos, világos és udvarias önkifejezés formális vagy informális stílusban az adott üzleti szituációnak és az érintett személyeknek megfelelő módon.\n' +
        ' \n' +
        'Jogi- és adóügyi ismeretek (2022. 02. 09. – 2022. 02. 16.): Adó, pénzügy blokkon belül ismeretfrissítés a számlázó program használatról, a számlázással kapcsolatos törvényi előírások ismertetése, a vállalkozás pénzügyi adatainak ismertetése, igazolások, banki utaláshoz szükséges adatok egyeztetése, szállító számlák kötelező tartalmi elemei, utalással és készpénz mozgással kapcsolatos belső szabályok. Rendszerhasználati blokk tartalmazta az általános biztonsági ismereteket, gépek, tartalmak, mentéseket, karbantartást, a GDPR szabályozást, a PCI DSS: biztonságos kártya kezelést, a Galileoval kapcsolatos készségfejlesztést, repülőjegy Interface és MRC ismereteket, Travelport képzést. Biztosítókkal kapcsolatban interface tudásfrissítés. Ezen felül jogi blokk keretein belül sajátíthatták el a főbb alkalmazandó jogszabályok újdonságait, az utazási szerződés típusait, és választ kaptak a munkavállalók egyéb, gyakorlati kérdésekre.\n' +
        'Komplett készségfejlesztés (2022. 04. 22. – 2022. 05. 20.): Komplett zöld, digitális és szociális készségfejlesztés a Covid utáni időkre. Szociális téren a résztvevők megismerhetik a problémamegoldás modern módszereit kisvállalkozásokra specializálva, a pozitív munkakörnyezet fenntartásának módszereit, az interkulturális megértést, az elérhető és befogadó turizmust. Digitális készségek terén megszerezhető kompetenciák az önkiszolgáló technológia megértéséhez kapcsolódnak, a kollaborációhoz, digitális technológiák felhasználásához, netiketthez. Zöld készségekből tanulhatnak az éghajlatváltozás utazási szokásokra való hatásáról és a fenntartható turizmus módszereiről.\n' +
        'Kommunikációs tréning (2022. 10. 07. – 2022. 12. 09.): Olyan kommunikációs ismereteket átadni és gyakoroltatni a résztvevőkkel, amelyek hozzásegítik őket ahhoz, hogy képesek legyenek bátran, magabiztosan és eredményesen kifejezni gondolataikat, véleményüket, képviselni érdekeiket a munkaerő-piacon és a mindennapi életben, szóban és írásban.\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>EU Támogatott képzések</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
                <img src={eu} alt=""/>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default EU